import React from 'react'
import cn from 'classnames'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import en from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'
import MaskedTextInput from 'react-text-mask'
import { useTranslation } from 'react-i18next'
import { LOCALES } from '../constants/locales'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import FormControl from '../directive/blocks/FormControl'
import { ReactComponent as CalendarIcon } from '../assets/images/icon-calendar.svg'
import 'react-datepicker/dist/react-datepicker.css'
import '../styles/blocks/react-datepicker-overrides.scss'

registerLocale(LOCALES.en, en)
registerLocale(LOCALES.es, es)

const DatePicker = ({ label, placeholder, dateFormat, input, meta, white, autoComplete = 'off', ...rest }) => {
  const { t, i18n } = useTranslation()

  const invalid = (meta && meta.touched && meta.error) || (meta && !!meta.submitError)
  const validationResult = invalid ? meta.error || meta.submitError : undefined
  const error = typeof validationResult === 'function' ? validationResult(t) : validationResult

  const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy', {
    maxYear: rest.maxDate && rest.maxDate.getFullYear(),
  })

  return (
    <FormControl
      className={cn('form-input -with-icon', { '-white': white })}
      label={label}
      invalid={invalid}
      error={error}
    >
      <CalendarIcon className="form-control_icon icon -icon-calendar" />
      <ReactDatePicker
        locale={i18n.language}
        selected={input.value}
        className="form-input_input form-control_input"
        {...placeholder}
        {...rest}
        autoComplete={autoComplete}
        onChange={input.onChange}
        name="startDate"
        dateFormat={dateFormat || 'MM/dd/yyyy'}
        customInput={
          <MaskedTextInput
            type="text"
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            pipe={autoCorrectedDatePipe}
          />
        }
      />
    </FormControl>
  )
}

export default DatePicker
