import React, { useState, useContext } from 'react'
import { Form, Field } from 'react-final-form'
import { find, propEq } from 'ramda'
import { useMutation } from '@apollo/react-hooks'
import { format, parseISO } from 'date-fns'

import Button from '../../directive/blocks/Button'
import FormFields from '../../directive/blocks/FormFields'
import FormInput from '../../directive/blocks/FormInput'
import Tile from '../../directive/blocks/Tile'
import FormSelect from '../../directive/blocks/FormSelect'
import { required, emailFormat, chain, nameValidation } from '../../inputs/validators'
import { UserContext } from '../../hoc'
import { SHARE_WITH_OTHER } from '../../common/mutations'
import { updateCacheSharedWith } from '../../common/cacheUtils'
import FormError from '../../directive/blocks/FormError'
import { useAnswers } from '../../directive/hooks/useAnswers'
import SelectorCheckbox from '../../directive/blocks/SelectorCheckbox'

import MD from '../../directive/blocks/Markdown'
import Modal from '../../directive/blocks/Modal'

import { getHIPPAATextByLocale, HIPAANoticeTitle } from './HIPPAAText'
import { EmailConsentTitle } from './emailConsentText'
import { useTranslation } from 'react-i18next'
import { LOCALE_OPTIONS } from '../../constants/locales'

const ShareWithOthers = (props) => {
  const { t } = useTranslation()

  const { sharedWithOthers } = React.useContext(UserContext)

  const [shareWithOther] = useMutation(SHARE_WITH_OTHER)

  const { documentInfo: { documentId, fileName } } = props

  const [formExpanded, setFormExpanded] = useState(false)

  const onExpand = () => {
    setFormExpanded(!formExpanded)
  }

  const onSubmit = async ({ fullName, email, locale }) => {
    await shareWithOther({
      variables: {
        email: email,
        fullName: fullName,
        locale: locale.value,
        fileName: fileName,
        documentId: documentId,
      },
      update: updateCacheSharedWith('shareWithOther', 'sharedWithOthers'),
    })
    setFormExpanded(false)
  }

  return (
    <div>
      {!!sharedWithOthers.length && (
        <Tile title={t('shareWithOthers.sentTo')} success onClick={onExpand}>
          {sharedWithOthers.map(({ id, fullName, email, createdAt, fileName }) => (
            <p key={id}>
              {fullName}
              <br />
              {email}
              <br />
              {fileName}
              <br />
              {format(parseISO(createdAt), 'MM/dd/yyyy')}
            </p>
          ))}
        </Tile>
      )}
      <Tile title={t('shareWithOthers.title')} expandable expanded={formExpanded} onTitleClick={onExpand}>
        <p>{t('shareWithOthers.text')}</p>
        <ShareWithOthersForm onSubmit={onSubmit} />
      </Tile>
    </div>
  )
}

const ShareWithOthersForm = ({ onSubmit }) => {
  const { t, i18n } = useTranslation()

  const me = useContext(UserContext)
  const { isDirectiveComplete } = useAnswers(me)

  const [HIPPAAIsOpen, setHIPPAAIsOpen] = useState(false)
  const [emailConsentIsOpen, setEmailConsentIsOpen] = useState(false)

  const currentLocaleOption = find(propEq('value', i18n.language))(LOCALE_OPTIONS)

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ locale: currentLocaleOption }}
      render={({ submitting, handleSubmit, values }) => {
        return (
          <form id="share-with-others" onSubmit={handleSubmit}>
            <FormFields>
              <Field
                name="fullName"
                validate={chain(required, nameValidation)}
                render={({ input, meta }) => (
                  <FormInput
                    label={t('common.otherPeopleControl.namePlaceholder')}
                    placeholder={t('common.otherPeopleControl.fullNamePlaceholder')}
                    type="text"
                    input={input}
                    meta={meta}
                    white
                  />
                )}
              />
              <Field
                name="email"
                validate={chain(required, emailFormat)}
                render={({ input, meta }) => (
                  <FormInput
                    label={t('form.emailLabel')}
                    placeholder={t('form.emailPlaceholder')}
                    type="text"
                    input={input}
                    meta={meta}
                    white
                  />
                )}
              />
              <Field name="locale" validate={required}>
                {({ input, meta }) => (
                  <FormSelect
                    label={t('form.languageLabel')}
                    placeholder={t('form.languagePlaceholder')}
                    options={LOCALE_OPTIONS}
                    input={input}
                    meta={meta}
                    white
                  />
                )}
              </Field>
              <Field type="checkbox" name={'hippa_checked'}>
                {({ input, meta }) => (
                  <SelectorCheckbox
                    name={'hippa_checked'}
                    label={
                      <HIPAANoticeTitle
                        onClick={(event) => {
                          event.preventDefault()
                          setHIPPAAIsOpen(true)
                        }}
                      />
                    }
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <Field type="checkbox" name={'email_checked'}>
                {({ input, meta }) => (
                  <SelectorCheckbox
                    name={'email_checked'}
                    label={
                      <EmailConsentTitle
                        onClick={(event) => {
                          event.preventDefault()
                          setEmailConsentIsOpen(true)
                        }}
                      />
                    }
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              {!isDirectiveComplete && (
                <FormError className="-with-margin-top">{t('shareDirective.notCompleteError')}</FormError>
              )}
            </FormFields>
            <Modal
              isOpen={HIPPAAIsOpen}
              onRequestClose={() => setHIPPAAIsOpen(false)}
              title={t('shareWith.HIPAANotice.modalTitle')}
            >
              <div className="modal_text">
                <MD allowParagraphs>{getHIPPAATextByLocale(i18n.language)}</MD>
              </div>
            </Modal>

            <Modal
              isOpen={emailConsentIsOpen}
              onRequestClose={() => setEmailConsentIsOpen(false)}
              title={t('shareWith.emailConsent.modalTitle')}
            >
              <div className="modal_text">
                <MD allowParagraphs>{t('shareWith.emailConsent.modalText')}</MD>
              </div>
            </Modal>
            <Button
              disabled={submitting || !isDirectiveComplete || !values.hippa_checked || !values.email_checked}
              type="submit"
            >
              {t('shareDirective.sendButton')}
            </Button>
          </form>
        )
      }}
    ></Form>
  )
}

export default ShareWithOthers
