import { LOCALES } from '../constants/locales'

const textEN = `
Now that you’ve created your Advance Directive, it’s important to talk about the care you do and do not want with your loved ones and your doctors.

### Talk With Your Loved Ones

Talking about your wishes with your loves ones ensures that they know what matters most to you. It prepares them to speak to your doctors for you if they ever need to.

-  Start with your healthcare proxy.

-  Choose a time and place that feels comfortable for both of you.

-  Think about the most important things you want to tell them.

-  Share your Advance Directive with them, if you haven’t already.

-  Begin with something you have in common. It could be concern about the future. Someone you both know who has faced difficult healthcare decisions. Values or religious beliefs you share.

-  Be patient with yourself and them. These conversations aren’t easy. It takes courage to have them.



### Talk With Your Doctors

Talking with your doctors about your wishes ensures they can answer your most important questions. It also gives them the information they need to guide your care.

-  Start with the doctor you want in charge of your care in a crisis.

-  Let your doctor know ahead of time that you want to discuss your advance care wishes. That way, the doctor can plan for the extra time it may take.

-  Before the appointment, think of the questions you want to ask and the most important things you want them to know.

-  Bring someone to help you remember what you want to say and what the doctor says to you.

-  Share your Advance Directive with them, if you haven’t already.

-  Tell your doctor how you make medical decisions, and who will speak for you if you can’t speak for yourself. Let your doctor know your wishes for care now, and in a crisis.



Conversations about your wishes help your loved ones support you and help your doctors provide treatments that you want. These conversations help all of you have peace of mind.

`

const textES = `
Ahora que ha creado su Directiva Anticipada, es importante hablar sobre la atención que desea y no desea con sus seres queridos y sus médicos.

### Hable con Sus Seres Queridos

Hablar de sus deseos con sus seres queridos asegura que ellos sepan lo que más le importa. Los prepara para hablar con sus médicos por usted si alguna vez necesitan hacerlo.

-  Comience con su representante de atención médica.

-  Elija un momento y un lugar en que ambos se sientan cómodos.

-  Piense en las cosas más importantes que quiera decirles.

-  Comparta su Directiva Anticipada con ellos, si aún no lo ha hecho.

-  Cuando llegue el momento, comience con algo que tenga en común. Podría ser la preocupación por el futuro. Alguien que ambos conocen que se haya enfrentado a decisiones de atención médica difíciles. O valores o creencias religiosas que comparten.

-  Sea paciente consigo mismo y con ellos. Estas conversaciones no son fáciles. Se necesita coraje para tenerlas.



### Hable con sus Médicos

Hablar con sus médicos sobre sus deseos garantiza que puedan responder sus preguntas más importantes. También les brinda la información que necesitan para guiarsu atención.

-  Comience con el médico que desea que esté a cargo de su atención en caso de crisis.

-  Informe a su médico con anticipación que desea hablar sobre sus deseos de atención anticipada. De esa forma, el médico puede planificar el tiempo adicional que puede llevar esa conversación.

-  Antes de la cita, piense en las preguntas que quiere hacer y las cosas más importantes que quiera decirles.

-  Traiga a alguien que le ayude a recordar lo que quiere decir y lo que le dice el médico.

-  Comparta su Directiva Anticipada con ellos, si aún no lo ha hecho.

-  Dígale a su médico como toma decisiones médicas y quien hablará por usted si usted no puede hacerlo por sí mismo. Informe a su médico sobre sus deseos de recibir atención ahora y en caso de crisis.



Las conversaciones sobre sus deseos ayudan a sus seres queridos a brindarle apoyo ya ayudar a sus médicos a brindarle los tratamientos que usted desea. Estas conversaciones les ayudan a todos a estar tranquilos.

`

export const getConversationTextByLocale = (locale) => {
  switch (locale) {
    case LOCALES.en:
      return textEN
    case LOCALES.es:
      return textES
    default:
      return textEN
  }
}
