import React from 'react'
import { Link } from 'react-router-dom'
import Layout from './blocks/Layout'
import Container from './blocks/Container'
import ForWhom from './blocks/ForWhom'
import PageTitle from './blocks/PageTitle'
import TileGroup from './blocks/TileGroup'
import Tile from './blocks/Tile'
import Button from './blocks/Button'
import Columns from '../directive/blocks/Columns'
import ColumnTop from '../directive/blocks/ColumnTop'
import ColumnLeft from '../directive/blocks/ColumnLeft'
import { forAuth, UserContext } from '../hoc'
import { useAnswers } from './hooks/useAnswers'
import { ReactComponent as ArrowBack } from '../assets/images/arrow-back.svg'
import { DASHBOARD_URL } from '../constants/UrlConstants'
import { useTranslation } from 'react-i18next'

const DirectiveSummary = () => {
  const { t } = useTranslation()

  const me = React.useContext(UserContext)
  const { summaryAnswers } = useAnswers(me)
  const filteredSummary = summaryAnswers.filter(({ summary }) => summary)

  return (
    <Layout>
      <div className="page-summary">
        <Container>
          <ColumnTop>
            <ForWhom>
              <Link to={DASHBOARD_URL}>
                <ArrowBack className="icon -arrow-back" /> {t('directiveSummary.linkBack')}
              </Link>
            </ForWhom>
            <PageTitle params={{ text: t('directiveSummary.title') }} />
          </ColumnTop>
          <Columns>
            <ColumnLeft>
              <TileGroup white>
                {filteredSummary.map(({ url, answers, isAnswered, summary }, directiveIndex) => (
                  <Tile key={directiveIndex} title={summary.title} white editLink={url}>
                    {isAnswered &&
                      summary.blocks &&
                      summary.blocks.map(({ component: Component, params }, blockIndex) => (
                        <Component key={blockIndex} params={params} answers={answers} />
                      ))}
                  </Tile>
                ))}
              </TileGroup>
              <Button to={DASHBOARD_URL} className="-link">
                <span className="button_icon-wrap -arrow-back">
                  <ArrowBack className="button_icon icon -arrow-back" />
                </span>
                <span className="button_text">{t('directiveSummary.linkBack')}</span>
              </Button>
            </ColumnLeft>
          </Columns>
        </Container>
      </div>
    </Layout>
  )
}

export default forAuth(DirectiveSummary)
