import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { formatISO, parseISO } from 'date-fns'
import arrayMutators from 'final-form-arrays'

import ReminderWidget from '../directive/blocks/Reminder'
import Modal from '../directive/blocks/Modal'
import ButtonGroup from '../directive/blocks/ButtonGroup'
import Button from '../directive/blocks/Button'
import MD from '../directive/blocks/Markdown'
import { Form, Field } from 'react-final-form'
import FormFields from '../directive/blocks/FormFields'
import DatePicker from '../inputs/DatePicker'
import DayMonthPicker from '../inputs/DayMonthPicker'
import { UserContext } from '../hoc'
import { CREATE_OR_UPDATE_REMINDER } from '../common/mutations'
import { updateCacheReminder } from '../common/cacheUtils'
import { dayMonthValidation } from '../inputs/validators'
import { useTranslation } from 'react-i18next'

const serializeReminderValues = ({ exactlyAt, annuallyAt }) => ({
  variables: {
    exactlyAt: exactlyAt ? formatISO(exactlyAt, { representation: 'date' }) : null,
    annuallyAtMonth: annuallyAt.month,
    annuallyAtDay: annuallyAt.day,
  },
  update: updateCacheReminder,
})

const Reminder = () => {
  const { t } = useTranslation()

  const { profile, reminder } = React.useContext(UserContext)
  const initialExactlyAt = reminder && reminder.exactlyAt ? parseISO(reminder.exactlyAt) : null
  const { annuallyAtMonth = null, annuallyAtDay = null } = reminder || {}

  const [createOrUpdateReminder] = useMutation(CREATE_OR_UPDATE_REMINDER)

  const [annuallyAt, setAnnuallyAt] = useState({ month: annuallyAtMonth, day: annuallyAtDay })
  const [exactlyAt, setExactlyAt] = useState(initialExactlyAt)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const onSubmit = ({ exactlyAt, annuallyAt }) => {
    closeModal()
    setExactlyAt(exactlyAt)
    setAnnuallyAt(annuallyAt)
    createOrUpdateReminder(serializeReminderValues({ exactlyAt, annuallyAt }))
  }

  return (
    <>
      <div role="button" onClick={openModal}>
        {/* For some reason doesn't work if I won't wrap button prop in e.g. <div> */}
        <ReminderWidget title={t('dashboard.reminder.title')} date={exactlyAt} annualDate={annuallyAt} />
      </div>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} title={t('dashboard.reminder.modal.title')}>
        <div className="modal_text">
          <MD allowParagraphs>{t('dashboard.reminder.modal.text')}</MD>

          <Form
            onSubmit={onSubmit}
            initialValues={{ exactlyAt, annuallyAt }}
            mutators={{
              ...arrayMutators,
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              },
            }}
            render={({ handleSubmit, form }) => (
              <form id="remindersForm" onSubmit={handleSubmit}>
                <FormFields>
                  <Field name="exactlyAt" label={t('form.onThisDateLabel')} component={DatePicker} />
                  <Field name="annuallyAt" validate={dayMonthValidation}>
                    {({ input, meta }) => (
                      <DayMonthPicker label={t('form.annuallyOn')} input={input} meta={meta} form={form} />
                    )}
                  </Field>
                </FormFields>

                <MD allowParagraphs>
                  {`${t('dashboard.reminder.modal.note')} [${profile.email}](mailto:${profile.email})`}
                </MD>
                <ButtonGroup className="-survey-nav">
                  <Button type="submit">{t('dashboard.reminder.modal.saveButton')}</Button>
                </ButtonGroup>
              </form>
            )}
          />
        </div>
      </Modal>
    </>
  )
}

export default Reminder
