import React from 'react'
import { useTranslation } from 'react-i18next'

export const EmailConsentTitle = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <>
      {t('shareWith.emailConsent.agreementText')}
      <br />
      <u onClick={onClick}>{t('shareWith.emailConsent.linkText')}</u>
    </>
  )
}
