import { LOCALES } from '../constants/locales'

const textEN = `
An Advance Directive offers guidance about your wishes for care in a crisis. However, doctors must also make their own medical judgments. Sometimes, that means they will give you life-saving treatments when your Advance Directive says you don’t want them.

Paramedics do not follow Advance Directives at all. They are required to do everything to save your life unless a medical order is in place.

Medical Orders are different from Advance Directives. They tell doctors and paramedics what treatments to give you. If you have a Medical Order in place, and a crisis occurs, you will only receive the treatments you chose.

There are several types of Medical Orders:

-  Do Not Resuscitate (DNR) and Do Not Intubate (DNI). These orders tell doctors not to use CPR if your heart stops or put a tube down your throat to help you breathe. These orders apply when you are in the hospital.

-  Portable Orders for Life-Sustaining Treatment (POLST). A POLST lets you and your doctor record your wishes for life-saving treatments. It includes orders for CPR, breathing machines, and feeding tubes on one form. These orders apply whether you are in the hospital or at home. Every state has its own version of the POLST; your state may call it MOLST, MOST, POST, or COLST.

Medical orders must be written and signed by a medical practitioner. You might talk with your doctor about a medical order if you have a very serious illness that will not improve. Or if your quality of life would no longer be acceptable after a crisis.
`

const textES = `
Una Directiva Anticipada ofrece orientación sobre sus deseos de atención en una crisis.Sin embargo, los médicos también deben emitir sus propios juicios médicos. A veces, eso significa que le suministrarán tratamientos que le salvarán la vida aun cuando su Directiva Anticipada indique que no los quiere.

Los paramédicos no siguen en absoluto las instrucciones de la Directiva Anticipada. Deben hacer todo lo posible para salvarle la vida a menos que exista una orden médica.

Las Órdenes Médicas les dicen a los médicos y paramédicos que tratamientos deben darle. Si tiene una Orden Médica en vigor y ocurre una crisis, solo recibirá los tratamientos que eligió.

Hay varios tipos de Órdenes Médicas:

-  No Resucitar (DNR, por sus siglas en inglés) y No Intubar (DNI,por sus siglas en inglés). Estas órdenes les dicen a los médicos que no utilicen Resucitación Cardiopulmonar si su corazón se detiene o que coloquen un tubo en su garganta para ayudarle a respirar. Estas órdenes aplican cuando está en el hospital.

-  Órdenes del Médico para Tratamientos de Soporte Vital (POLSTpor sus siglas en inglés). Una POLST le permite a usted y a su médico registrar sus deseos de tratamientos que salvan vidas. Incluye órdenes de Resucitación Cardiopulmonar, respiradores y una forma de alimentación enteral. Estas órdenes aplican tanto si se encuentra en el hospital como en casa. Cada estado tiene su propia versión del POLST; su estado puede llamarlo MOLST, MOST, POST o COLST.

Las órdenes médicas deben estar escritas y firmadas por un médico. Puede hablar con su médico sobre una orden médica si tiene una enfermedad muy grave que no mejorará. O si su calidad de vida ya no sería aceptable después de una crisis.
`

export const getMedicalOrdersPopupTextByLocale = (locale) => {
  switch (locale) {
    case LOCALES.en:
      return textEN
    case LOCALES.es:
      return textES
    default:
      return textEN
  }
}
