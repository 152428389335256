import React from 'react'
import { useTranslation } from 'react-i18next'
import MD from '../blocks/Markdown'

import VDLogo from '../../assets/images/logos/vital-decisions.svg'
import '../../styles/blocks/vd-header-info.scss'

const VitalDecisionsHeaderInfo = () => {
  const { t } = useTranslation()

  return (
    <div className="vd-header-info">
      <div className="vd-header-info_item">
        <img className="vd-header-info_logo" src={VDLogo} />
      </div>
      <div className="vd-header-info_item">
        <div className="vd-header-info_text">
          <MD>{t('signUp.headerAdditionalText')}</MD>
        </div>
      </div>
    </div>
  )
}

export default VitalDecisionsHeaderInfo
