import { useMutation } from '@apollo/react-hooks'

import { isEmpty, compose, reduce, mergeDeepLeft, map, assoc, prop, path, reject, pathEq } from 'ramda'

import { useProxy } from './useProxy'
import { PUT } from '../../common/requests'
import { calculateChecksum } from '../../common/checksumUtils'
import { SUBMIT_DIRECTIVE_ANSWER, CREATE_DIRECT_UPLOAD, ATTACH_SIGNATURE_TO_USER } from '../../common/mutations'

export const useDirective = ({ answers, proxies, profile, history }) => {
  const [submitAnswer] = useMutation(SUBMIT_DIRECTIVE_ANSWER)
  const [createDirectUpload] = useMutation(CREATE_DIRECT_UPLOAD)
  const [attachSignatureToUser] = useMutation(ATTACH_SIGNATURE_TO_USER)

  const { createProxyRequest } = useProxy()

  const saveSignature = (signature) => {
    if (!profile.signatureUrl && signature.isEmpty()) {
      return
    }

    new Promise((resolve) => {
      signature.canvas.toBlob(async (blob) => {
        const checksum = await calculateChecksum(blob)

        if (profile.signatureUrl) {
          const previousSignatureResponse = await fetch(profile.signatureUrl)
          const previousBlob = await previousSignatureResponse.blob()
          const previousChecksum = await calculateChecksum(previousBlob)

          if (checksum === previousChecksum) {
            resolve()
            return
          }
        }

        const {
          data: {
            createDirectUpload: { directUpload },
          },
        } = await createDirectUpload({
          variables: {
            byteSize: blob.size,
            checksum: checksum,
            contentType: 'image/png',
            filename: 'signature',
          },
        })
        const { url, headers, signedBlobId } = directUpload
        await PUT('', blob, url, JSON.parse(headers))()
        await attachSignatureToUser({ variables: { blobId: `${signedBlobId}` } })
        resolve()
      })
    })
  }

  const redirectToNextPage = (page, values) => {
    const nextPage = page.getNextStep(values)
    history.push(nextPage.url)
  }

  const onSubmit = (page) => async (values, form, onSuccess = redirectToNextPage) => {
    const validationResult = page.validate ? page.validate(values) : {}

    if (!!validationResult && Object.entries(validationResult).length > 0) {
      return validationResult
    }

    const { pristine } = form.getState()

    if (pristine && !Object.values(values).some(Boolean)) {
      onSuccess(page, values)
      return
    }

    let submitErrors = {}


    if (page.slug === 'identify-proxy') {
      const initialProxies = page.setInitialValues(answers, proxies)
      const { serializedProxies, serializedAnswers } = page.serializeValues(values)
      const requests = []

      if (serializedAnswers) {
        await submitAnswer(serializedAnswers)
      }

      if (values.primary.id) {
        // update primary
        requests.push(createProxyRequest('updateProxy', serializedProxies[0], 'primary'))
      } else {
        // create primary
        requests.push(createProxyRequest('createProxy', serializedProxies[0], 'primary'))
      }

      if (values.secondary && !isEmpty(values.secondary)) {
        if (values.secondary.id) {
          // update secondary
          requests.push(createProxyRequest('updateProxy', serializedProxies[1], 'secondary'))
        } else {
          // delete old secondary if exists
          if (initialProxies.secondary && !isEmpty(initialProxies.secondary)) {
            requests.push(createProxyRequest('deleteProxy', { id: initialProxies.secondary.id }, 'secondary'))
          }
          // create new secondary
          requests.push(createProxyRequest('createProxy', serializedProxies[1], 'secondary'))
        }
      } else {
        if (initialProxies.secondary && !isEmpty(initialProxies.secondary)) {
          // delete old secondary
          requests.push(createProxyRequest('deleteProxy', { id: initialProxies.secondary.id }, 'secondary'))
        }
      }

      const response = await Promise.all(requests.map((request) => request.func()))

      submitErrors = compose(
        reduce((acc, val) => mergeDeepLeft(acc, val), {}),
        map((proxy) => assoc(prop('proxyPath')(proxy), path(['data', 'errors'])(proxy), {})),
        reject(pathEq(['data', 'errors', 'length'], 0)),
      )(response)
    } else if (page.slug === 'sign-directive') {
      await saveSignature(values.signature)
    } else {
      const serializedValue = page.serializeValues(values)

      if (Array.isArray(serializedValue)) {
        const promises = serializedValue.map((value) => submitAnswer(value))
        await Promise.all(promises)
      } else if (serializedValue) {
        await submitAnswer(serializedValue)
      }
    }

    if (isEmpty(submitErrors)) {
      onSuccess(page, values)
    } else {
      return submitErrors
    }
  }

  return { onSubmit }
}
