import { useMutation } from '@apollo/react-hooks'

import { CREATE_PROXY, UPDATE_PROXY, DELETE_PROXY } from '../../common/mutations'
import { updateCacheDeleteProxy, updateCacheAddProxy } from '../../common/cacheUtils'
import DOMPurify from 'dompurify'


export const useProxy = () => {
  const [createProxy] = useMutation(CREATE_PROXY)
  const [updateProxy] = useMutation(UPDATE_PROXY)
  const [deleteProxy] = useMutation(DELETE_PROXY)

  const purifyConfig = {
    ALLOWED_TAGS: [],
  }

  const createProxyRequest = (actionType, variables, proxyPath) => {
    variables = {
      firstName: DOMPurify.sanitize(variables.firstName, purifyConfig), 
      lastName: DOMPurify.sanitize(variables.lastName, purifyConfig),
      address: DOMPurify.sanitize(variables.address, purifyConfig),
      city: DOMPurify.sanitize(variables.city, purifyConfig),
      email: DOMPurify.sanitize(variables.email, purifyConfig),
      ...variables,
    }
    switch (actionType) {
      case 'updateProxy':
        return {
          name: 'updateProxy',
          func: async () => {
            const { data } = await updateProxy({ variables })
            return {
              data: data.updateProxy,
              type: 'updateProxy',
              proxyPath,
            }
          },
        }
      case 'deleteProxy':
        return {
          name: 'deleteProxy',
          func: async () => {
            const { data } = await deleteProxy({ variables, update: updateCacheDeleteProxy })

            return {
              data: data.deleteProxy,
              type: 'deleteProxy',
              proxyPath,
            }
          },
        }

      case 'createProxy':
        return {
          name: 'createProxy',
          func: async () => {
            const { data } = await createProxy({ variables, update: updateCacheAddProxy })

            return {
              data: data.createProxy,
              type: 'createProxy',
              proxyPath,
            } 
          },
        }
      default:
        return {}
    }
  }

  return { createProxyRequest }
}
