import React from 'react'

import FormSelect from '../directive/blocks/FormSelect'
import { monthsSelectOptions, getDaysSelectOptions } from '../constants/months'

import '../styles/blocks/day-month-picker.scss'
import { useTranslation } from 'react-i18next'

const DayMonthPicker = ({ label, input, meta, form }) => {
  const { t } = useTranslation()

  const { month, day } = input.value
  const choosedMonth = month ? monthsSelectOptions[month] : null
  const dayOptions = getDaysSelectOptions(month)
  const choosedDay = day ? dayOptions[day] : null

  const error = meta.error && meta.error(t)

  const onMonthChange = (option) => {
    form.mutators.setValue(`${input.name}[month]`, option.value)
    form.mutators.setValue(`${input.name}[day]`, null)
  }

  const onDayChange = (option) => {
    form.mutators.setValue(`${input.name}[day]`, option.value)
  }

  return (
    <div className="day-month-picker">
      {label && <div className="day-month-picker_label">{label}</div>}
      <div className="day-month-picker_content">
        <div className="day-month-picker_month">
          <FormSelect
            placeholder={t('form.monthPlaceholder')}
            options={monthsSelectOptions}
            input={{ ...input, value: choosedMonth, onChange: onMonthChange }}
            meta={{ ...meta, error: error && error.month }}
            noMargin={true}
          />
        </div>
        <div className="day-month-picker_day">
          <FormSelect
            placeholder={t('form.dayPlaceholder')}
            options={dayOptions}
            input={{ ...input, value: choosedDay, onChange: onDayChange, isDisabled: !month }}
            meta={{ ...meta, error: meta.submitFailed && error && error.day }}
            noMargin={true}
          />
        </div>
      </div>
    </div>
  )
}

export default DayMonthPicker
