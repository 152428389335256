import React, { useState, useContext } from 'react'
import { Field, Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import { format, parseISO } from 'date-fns'
import { isEmpty, mapObjIndexed } from 'ramda'
import cn from 'classnames'

import Button from '../../directive/blocks/Button'
import FormFields from '../../directive/blocks/FormFields'
import Tile from '../../directive/blocks/Tile'
import { UserContext } from '../../hoc'
import { SHARE_WITH_AD_VAULT, REMOVE_FROM_AD_VAULT } from '../../common/mutations'
import { updateCacheSharedWith } from '../../common/cacheUtils'
import SelectorCheckbox from '../../directive/blocks/SelectorCheckbox'
import FormError from '../../directive/blocks/FormError'
import { useAnswers } from '../../directive/hooks/useAnswers'
import MD from '../../directive/blocks/Markdown'
import Modal from '../../directive/blocks/Modal'
import TooltipList from '../../directive/blocks/TooltipList'
import { getHIPPAATextByLocale, HIPAANoticeTitle } from './HIPPAAText'
import { showError } from '../../common/toastUtils'
import { chain, datePast, required } from '../../inputs/validators'
import DatePicker from '../../inputs/DatePicker'

const ShareWithAdVault = () => {
  const { t } = useTranslation()
  const { sharedWithAdVault } = useContext(UserContext)
  const [shareWithAdVault] = useMutation(SHARE_WITH_AD_VAULT)
  const [removeFromAdVault] = useMutation(REMOVE_FROM_AD_VAULT)

  const [formExpanded, setFormExpanded] = useState(false)

  const onExpand = () => {
    setFormExpanded(!formExpanded)
  }

  const onSubmit = async ({ dateOfBirth }) => {
    const { data } = await shareWithAdVault({
      variables: {
        dateOfBirth: dateOfBirth,
      },
      update: updateCacheSharedWith('shareWithAdVault', 'sharedWithAdVault'),
    })
    if (data.shareWithAdVault.errors && !isEmpty(data.shareWithAdVault.errors)) {
      mapObjIndexed((error) => showError(error), data.shareWithAdVault.errors)
    }
    setFormExpanded(false)
  }

  const onRemove = async () => {
    const { data } = await removeFromAdVault({
      update: updateCacheSharedWith('removeFromAdVault', 'sharedWithAdVault'),
    })
    if (data.removeFromAdVault.errors && !isEmpty(data.removeFromAdVault.errors)) {
      mapObjIndexed((error) => showError(error), data.removeFromAdVault.errors)
    }
    setFormExpanded(false)
  }

  const shareText = (share) => {
    const date = format(parseISO(share.createdAt), 'MM/dd/yyyy')
    const prefix = share.shareType === 'ad_vault_remove' ? `${t('shareDirective.removed_on')} ` : ''
    const postfix =
      { in_progress: ` - ${t('shareDirective.in_progress')}`, failed: ` - ${t('shareDirective.failed')}` }[
        share.status
      ] || ''

    return <p key={share.id}>{prefix + date + postfix}</p>
  }

  return (
    <div>
      {!!sharedWithAdVault.length && (
        <Tile title={t('shareWithAdVault.sentTo')} success onClick={onExpand}>
          {sharedWithAdVault.map(shareText)}
        </Tile>
      )}
      <Tile title={t('shareWithAdVault.title')} expandable expanded={formExpanded} onTitleClick={onExpand}>
        <TooltipList
          params={{
            tooltips: [
              {
                title: t('shareWithAdVault.tooltips.0.title'),
                reTitle: t('shareWithAdVault.tooltips.0.reTitle'),
                text: t('shareWithAdVault.tooltips.0.text'),
              },
            ],
          }}
        />
        <ShareAdVaultForm onSubmit={onSubmit} onRemove={onRemove} />
      </Tile>
    </div>
  )
}

const ShareAdVaultForm = ({ onSubmit, onRemove }) => {
  const { t, i18n } = useTranslation()

  const me = useContext(UserContext)
  const { sharedWithAdVault } = useContext(UserContext)
  const { isDirectiveComplete } = useAnswers(me)
  const [HIPPAAIsOpen, setHIPPAAIsOpen] = useState(false)
  const initialDateOfBirthValue = me.profile.dateOfBirth && parseISO(me.profile.dateOfBirth)

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value)
        },
      }}
      render={({ handleSubmit, values, submitting }) => {
        return (
          <form id="share-with-ad-vault" onSubmit={handleSubmit}>
            <Field
              name="dateOfBirth"
              validate={chain(required, datePast)}
              initialValue={initialDateOfBirthValue}
              render={({ input, meta }) => (
                <DatePicker
                  label={t('form.yourDateOfBirthLabel')}
                  type="text"
                  input={input}
                  meta={meta}
                  showMonthDropdown
                  showYearDropdown
                  maxDate={new Date()}
                  yearDropdownItemNumber={120}
                  scrollableYearDropdown
                  white
                />
              )}
            />
            <FormFields>
              <Field type="checkbox" name={'hippa_checked'}>
                {({ input, meta }) => (
                  <SelectorCheckbox
                    name="hippa_checked"
                    input={input}
                    meta={meta}
                    label={
                      <HIPAANoticeTitle
                        onClick={(event) => {
                          event.preventDefault()
                          setHIPPAAIsOpen(true)
                        }}
                      />
                    }
                  />
                )}
              </Field>
              {!isDirectiveComplete && (
                <FormError className="-with-margin-top">{t('shareDirective.notCompleteError')}</FormError>
              )}
            </FormFields>
            <Modal
              isOpen={HIPPAAIsOpen}
              onRequestClose={() => setHIPPAAIsOpen(false)}
              title={t('shareWith.HIPAANotice.modalTitle')}
            >
              <div className="modal_text">
                <MD allowParagraphs>{getHIPPAATextByLocale(i18n.language)}</MD>
              </div>
            </Modal>

            <Button disabled={submitting || !isDirectiveComplete || !values.hippa_checked} type="submit">
              {t('shareDirective.sendButton')}
            </Button>

            {sharedWithAdVault.length ? (
              <Button
                disabled={!isDirectiveComplete || !values.hippa_checked}
                className={cn({ '-red': isDirectiveComplete && values.hippa_checked }, '-second')}
                onClick={onRemove}
                type="button"
              >
                {t('shareDirective.removeButton')}
              </Button>
            ) : null}
          </form>
        )
      }}
    ></Form>
  )
}

export default ShareWithAdVault
