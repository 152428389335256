import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/react-hooks'
import { formatISO, parseISO } from 'date-fns'

import { getStatesOptions } from '../constants/UsStates'
import FormFields from '../directive/blocks/FormFields'
import FormInput from '../directive/blocks/FormInput'
import FormSelect from '../directive/blocks/FormSelect'
import { capitalize, trim, chain as formatChain } from '../inputs/formatters'
import { required, minLength, emailFormat, chain, datePast, nameValidation } from '../inputs/validators'
import Layout from '../directive/blocks/Layout'
import Container from '../directive/blocks/Container'
import ForWhom from '../directive/blocks/ForWhom'
import PageTitle from '../directive/blocks/PageTitle'
import Tile from '../directive/blocks/Tile'
import Columns from '../directive/blocks/Columns'
import ColumnTop from '../directive/blocks/ColumnTop'
import ColumnLeft from '../directive/blocks/ColumnLeft'
import { ReactComponent as ArrowBack } from '../assets/images/arrow-back.svg'
import { forAuth, UserContext } from '../hoc'
import ButtonGroup from '../directive/blocks/ButtonGroup'
import Button from '../directive/blocks/Button'
import { UPDATE_MY_PROFILE } from '../common/mutations'
import DatePicker from '../inputs/DatePicker'
import DOMPurify from 'dompurify'

const PROFILE_LINK = '/profile'

const purifyConfig = {
  ALLOWED_TAGS: [],
}

const serializeProfileValues = (values) => ({
  variables: {
    email: values.email,
    firstName: DOMPurify.sanitize(values.firstName, purifyConfig),
    lastName: DOMPurify.sanitize(values.lastName, purifyConfig),
    residenceState: values.residenceState.value,
    dateOfBirth: values.dateOfBirth ? formatISO(values.dateOfBirth, { representation: 'date' }) : null,
  },
})

const ProfileForm = ({ onSubmit, data: { firstName, lastName, residenceState, email, dateOfBirth } }) => {
  const { t } = useTranslation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const statesOptions = useMemo(() => getStatesOptions(t), [])

  const stateOption = statesOptions.find(({ value }) => value === residenceState)
  const date = dateOfBirth ? parseISO(dateOfBirth) : null

  return (
    <Form
      onSubmit={onSubmit}
      render={({ submitting, handleSubmit }) => (
        <form id="userForm" onSubmit={handleSubmit}>
          <FormFields>
            <Field
              name="firstName"
              initialValue={firstName}
              validate={chain(required, minLength(2), nameValidation)}
              format={formatChain(trim, capitalize)}
              type="text"
              render={({ input, meta }) => (
                <FormInput
                  label={t('form.firstNameLabel')}
                  placeholder={t('form.firstNamePlaceholder')}
                  input={input}
                  meta={meta}
                />
              )}
            />

            <Field
              name="lastName"
              initialValue={lastName}
              validate={chain(required, nameValidation)}
              format={formatChain(trim, capitalize)}
              render={({ input, meta }) => (
                <FormInput
                  label={t('form.lastNameLabel')}
                  placeholder={t('form.lastNamePlaceholder')}
                  type="text"
                  input={input}
                  meta={meta}
                />
              )}
            />

            <Field name="residenceState" initialValue={stateOption} validate={required}>
              {({ input, meta }) => (
                <FormSelect
                  label={t('form.stateLabel')}
                  options={statesOptions}
                  placeholder={t('form.statePlaceholder')}
                  input={input}
                  meta={meta}
                />
              )}
            </Field>

            <Field name="email" initialValue={email} validate={chain(required, emailFormat)}>
              {({ input, meta }) => (
                <FormInput
                  label={t('form.emailLabel')}
                  placeholder={t('form.emailPlaceholder')}
                  type="text"
                  input={input}
                  meta={meta}
                />
              )}
            </Field>

            <Field
              name="dateOfBirth"
              initialValue={date}
              validate={datePast}
              render={({ input, meta }) => (
                <DatePicker
                  label={t('form.dateOfBirthLabel')}
                  type="text"
                  input={input}
                  meta={meta}
                  showMonthDropdown
                  showYearDropdown
                  maxDate={new Date()}
                  yearDropdownItemNumber={120}
                  scrollableYearDropdown
                />
              )}
            />

            <ButtonGroup className="-survey-nav">
              <Button disabled={submitting} type="submit">
                {t('profileEdit.saveButton')}
              </Button>
            </ButtonGroup>
          </FormFields>
        </form>
      )}
    />
  )
}

const ProfilePage = ({ history }) => {
  const { t } = useTranslation()
  const { profile } = React.useContext(UserContext)
  const [updateProfile] = useMutation(UPDATE_MY_PROFILE)

  const onSubmit = async (values) => {
    const response = await updateProfile(serializeProfileValues(values))
    const errors = response.data.updateMyProfile.errors

    if (errors && errors.length === 0) {
      history.push(PROFILE_LINK)
    } else {
      return errors
    }
  }

  return (
    <Layout>
      <div className="page-profile">
        <Container>
          <ColumnTop>
            <ForWhom>
              <Link to={PROFILE_LINK}>
                <ArrowBack className="icon -arrow-back" /> {t('profileEdit.linkBack')}
              </Link>
            </ForWhom>
            <PageTitle params={{ text: t('profileEdit.title') }} />
          </ColumnTop>
          <Columns>
            <ColumnLeft>
              <Tile white>
                <ProfileForm data={profile} onSubmit={onSubmit}></ProfileForm>
              </Tile>
            </ColumnLeft>
          </Columns>
        </Container>
      </div>
    </Layout>
  )
}

export default forAuth(ProfilePage)
