import React from 'react'
import cn from 'classnames'
import MD from '../../directive/blocks/Markdown'
import { format, isValid } from 'date-fns'

import { ReactComponent as IconBell } from '../../assets/images/icon-bell.svg'
import { ReactComponent as ArrowRight } from '../../assets/images/arrow-corner-right.svg'

import '../../styles/blocks/reminder.scss'
import { useTranslation } from 'react-i18next'

const ReminderWidget = ({ title, date, annualDate, className }) => {
  const { t } = useTranslation()

  return (
    <div className={cn('reminder', className)}>
      <ArrowRight className="reminder_icon icon -arrow-corner-right" />
      <div className="reminder_title">
        <IconBell className="reminder_title-icon icon -icon-bell" />
        <span className="reminder_title-text">
          <MD>{title}</MD>
        </span>
      </div>
      <div className="reminder_date">
        {date && isValid(date) && (
          <>
            {t('dashboard.reminder.onDate')} {format(date, 'MMMM do yyyy')}
          </>
        )}
        {annualDate.month && annualDate.day && (
          <>
            <br />
            {t('dashboard.reminder.annually')} {format(new Date(0, annualDate.month - 1, annualDate.day), 'MMMM do')}
          </>
        )}
        {!date && !(annualDate.month && annualDate.day) && t('dashboard.reminder.empty')}
      </div>
    </div>
  )
}

export default ReminderWidget
