import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import Layout from '../directive/blocks/Layout'
import Container from '../directive/blocks/Container'
import TooltipList from '../directive/blocks/TooltipList'
import Button from '../directive/blocks/Button'
import ForWhom from '../directive/blocks/ForWhom'
import TileGroup from '../directive/blocks/TileGroup'
import Tile from '../directive/blocks/Tile'
import TickList from '../directive/blocks/TickList'
import Columns from '../directive/blocks/Columns'
import ColumnTop from '../directive/blocks/ColumnTop'
import ColumnLeft from '../directive/blocks/ColumnLeft'
import ColumnRight from '../directive/blocks/ColumnRight'
import Reminder from './Reminder'

import { ReactComponent as IconShare } from '../assets/images/icon-share.svg'
import { ReactComponent as IconDownload } from '../assets/images/icon-download.svg'
import { ReactComponent as IconPencil } from '../assets/images/icon-pencil.svg'
import { forAuth, UserContext } from '../hoc'
import { useAnswers } from '../directive/hooks/useAnswers'
import { getConversationTextByLocale } from './ConversationPopupText'
import { getMedicalOrdersPopupTextByLocale } from './MedicalOrdersPopupText'
import PageText from '../directive/blocks/PageText'
import { useTranslation } from 'react-i18next'


const Tooltips = ({ className }) => {
  const { t, i18n } = useTranslation()

  return (
    <>
      <PageText className={className} params={{ text: `__*${t('dashboard.nextSteps.title')}*__` }} />
      <TooltipList
        className={className}
        params={{
          tooltips: [
            {
              title: t('dashboard.nextSteps.tooltips.0.title'),
              reTitle: t('dashboard.nextSteps.tooltips.0.reTitle'),
              text: t('dashboard.nextSteps.tooltips.0.text'),
            },
            {
              title: t('dashboard.nextSteps.tooltips.1.title'),
              reTitle: t('dashboard.nextSteps.tooltips.1.reTitle'),
              text: getConversationTextByLocale(i18n.language),
            },
            {
              title: t('dashboard.nextSteps.tooltips.2.title'),
              reTitle: t('dashboard.nextSteps.tooltips.2.reTitle'),
              text: getMedicalOrdersPopupTextByLocale(i18n.language),
            },
          ],
        }}
      />
    </>
  )
}

const UserDashboard = () => {
  const { t } = useTranslation()

  const me = useContext(UserContext)
  const { summaryAnswers, isDirectiveComplete } = useAnswers(me)
  const { profile } = me

  const dashboardItems = summaryAnswers.filter(({ dashboard }) => !!dashboard)



  return (
    <Layout>
      <div className="page-dashboard">
        <Container>
          <ColumnTop>
            <ForWhom>
              {t('dashboard.for')} <Link to="/profile">{`${profile.firstName} ${profile.lastName}`}</Link>
            </ForWhom>
          </ColumnTop>
          <Columns>
            <ColumnLeft>
              <TileGroup>
                <Tile title={t('dashboard.title')} to="/directive/summary" white>
                  <TickList
                    dull
                    items={dashboardItems.map(({ dashboard, isAnswered }) => ({
                      text: dashboard.title,
                      icon: isAnswered ? 'tick' : 'minus',
                    }))}
                  />
                  <Button className="-link-flex">
                    <span className="button_icon-wrap -icon-share">
                      <IconPencil className="button_icon icon -icon-pencil" />
                    </span>
                    <span className="button_text">
                      {t('dashboard.view.edit.answers')}
                    </span>
                  </Button>
                </Tile>
                {!isDirectiveComplete && <Tile to="/directive/summary">
                  <Button
                    className="-link-flex"
                  >
                    <span className="button_icon-wrap -icon-share">
                      <IconDownload className="button_icon icon -icon-share" />
                    </span>
                    <span className="button_text">
                      {t('dashboard.complete.directive')}
                    </span>
                  </Button>
                </Tile>}
                {isDirectiveComplete && <Tile to="/directive/execute">
                  <Button
                    className="-link-flex"
                  >
                    <span className="button_icon-wrap -icon-share">
                      <IconDownload className="button_icon icon -icon-share" />
                    </span>
                    <span className="button_text">
                      {t('dashboard.directiveNotComplete.button')}
                    </span>
                  </Button>
                </Tile>
                }
                <Tile white to="/documents">
                  <Button className="-link-flex">
                    <span className="button_icon-wrap -icon-share">
                      <IconShare className="button_icon icon -icon-share" />
                    </span>
                    <span className="button_text">{t('dashboard.documentsLink')}</span>
                  </Button>
                  <PageText params={{ text: t('dashboard.documentsLinkSubtext') }} className="subtext" />
                </Tile>
              </TileGroup>
              <Reminder />

              <TooltipList
                params={{
                  tooltips: [
                    {
                      title: t('dashboard.tooltips.0.title'),
                      reTitle: t('dashboard.tooltips.0.reTitle'),
                      text: t('dashboard.tooltips.0.text'),
                    },
                  ],
                }}
              />

              <Tooltips className="-desktop-hide" />
            </ColumnLeft>
            <ColumnRight>
              <Tooltips />
            </ColumnRight>
          </Columns>
        </Container>
      </div >
    </Layout >
  )
}

export default forAuth(UserDashboard)
