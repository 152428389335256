import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'

import FormFields from '../directive/blocks/FormFields'
import FormInput from '../directive/blocks/FormInput'
import { required, chain, emailFormat } from '../inputs/validators'
import Layout from '../directive/blocks/Layout'
import Container from '../directive/blocks/Container'
import PageTitle from '../directive/blocks/PageTitle'
import Tile from '../directive/blocks/Tile'
import Columns from '../directive/blocks/Columns'
import ColumnTop from '../directive/blocks/ColumnTop'
import ButtonGroup from '../directive/blocks/ButtonGroup'
import Button from '../directive/blocks/Button'
import { POST, REQUESTS, redirectToLogin } from '../common/requests'
import { useTranslation } from 'react-i18next'

const RecoveryPage = () => {
  const { t } = useTranslation()

  const [isHandlingResetPassword, setIsHandlingResetPassword] = useState(false)

  const onSubmit = async ({ email }) => {
    setIsHandlingResetPassword(true)
    const { error } = await POST(REQUESTS.RESET_PASSWORD, { user: { email } })()
    setIsHandlingResetPassword(false)

    if (!error) {
      redirectToLogin()
    }
  }

  return (
    <Layout>
      <div className="page-reset">
        <Container>
          <Columns>
            <ColumnTop>
              <PageTitle params={{ text: t('passwordReset.title') }} />
              <Tile white>
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit }) => (
                    <form id="resetForm" onSubmit={handleSubmit}>
                      <FormFields>
                        <Field name="email" validate={chain(required, emailFormat)}>
                          {({ input, meta }) => (
                            <FormInput
                              label={t('form.emailLabel')}
                              placeholder={t('form.emailPlaceholder')}
                              type="text"
                              input={input}
                              meta={meta}
                            />
                          )}
                        </Field>
                        <ButtonGroup className="-survey-nav">
                          <Button type="submit" disabled={isHandlingResetPassword}>
                            {t('passwordReset.submitButton')}
                          </Button>
                        </ButtonGroup>
                      </FormFields>
                    </form>
                  )}
                />
              </Tile>
            </ColumnTop>
          </Columns>
        </Container>
      </div>
    </Layout>
  )
}

export default RecoveryPage
