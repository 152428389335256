import React from 'react'
import { useTranslation } from 'react-i18next'
import { LOCALES } from '../../constants/locales'

export const HIPAANoticeTitle = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <>
      {t('shareWith.HIPAANotice.agreementText')} <u onClick={onClick}>{t('shareWith.HIPAANotice.linkText')}</u>
    </>
  )
}

const textEN = `

### How It Works

In just a few short minutes our guided experience will help you express what matters most to you, identify your healthcare proxy, and designate your wishes for medical treatment in a medical crisis.

At the end of the survey you'll be able to review, download and share your advance directive to help guide your medical care and ensure the care your want aligns with the care you receive.

### HIPAA NOTICE AND CONSENT

This notice describes how medical information you provide to us through our platform, or which you otherwise provide to us (i.e., via e-mail, through our website, via a phone call, etc.) may be used and disclosed and how you can get access to this information. 
__Please review it carefully__.

#### Our Uses and Disclosures

We may use and share your information as we:

-  Provide our services as identified at [mylivingvoice.com](http://www.mylivingvoice.com) and in connection with the platform described above
-  Run our organization
-  Help with public health and safety issues
-  Conduct research
-  Comply with the law
-  Address law enforcement and other government requests
-  Respond to lawsuits and legal actions

#### Your Rights

__When it comes to your information, you have certain rights.__ This section explains your rights and some of our responsibilities to help you.

__Get an electronic or paper copy of your medical information you provided to us.__

-  You can ask to see or get an electronic or paper copy of your medical information and other health information we have about you by submitting a request in writing to:

    __Vital Decisions__  
    __379 Thornall St 3rd Floor__  
    __Edison, NJ 08837__  
    __ATTN: Chief Clinical Officer__  

    __Or by secure confidential fax to:__  
    __866-449-8856__  
    __ATTN: Chief Clinical Officer__  

-  We will provide a copy or a summary of your health information, usually within 30 days of your request. We may charge a reasonable, cost-based fee.

__Ask us to correct your medical record__

- You can ask us to correct health information about you that you think is incorrect or incomplete. You must make the request to amend in writing with a reason. The request should be requested on Request for Amendment of Health Information form and submitted to:

    __Vital Decisions__  
    __379 Thornall St 3rd Floor__  
    __Edison, NJ 08837__  
    __ATTN: Chief Clinical Officer__

    __Or by secure confidential fax to:__  
    __866-449-8856__  
    __ATTN: Chief Clinical Officer__

-  We may say &quot;no&quot; to your request, but we will tell you why in writing within 60 days.

**Request confidential communications**

- You can ask us to contact you in a specific way (for example, home or office phone) or to send mail to a different address.
- We will say &quot;yes&quot; to all reasonable requests.
- However, if you contact us in a specific manner (i.e., unsecured e-mail), we will assume that you consent to us communicating back to you, in response to your communication, via the same mechanism/manner unless you explicitly tell us otherwise.

**Ask us to limit what we use or share**

- You can ask us not to use or share certain health information for payment or our operations. We are not required to agree to your request, and we may say &quot;no&quot; if it would affect your care.

**Get a list of those with whom we have shared your health or medical information**

- You can ask for a list (accounting) of the times we&#39;ve shared your health or medical information for 6 years prior to the date you ask, with whom we shared it, and why.

**Get a copy of this privacy notice**

- You can ask for a paper copy of this notice at any time, even if you have agreed to receive the notice electronically. We will provide you with a paper copy promptly.

**Choose someone to act for you**

- If you have given someone medical power of attorney or if someone is your legal guardian, that person can exercise your rights and make choices about your health information.
- We will make sure the person has this authority and can act for you before we take any action.

**File a complaint if you feel your rights are violated**

- You can complain if you feel we have violated your rights by contacting us by sending an email to: [memberservices@vitaldecisions.net](mailto:memberservices@vitaldecisions.net)
- You can file a complaint with the U.S. Department of Health and Human Services Office for Civil Rights by sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201, calling 1-877-696-6775, or visiting [http://www.hhs.gov/ocr/privacy/hipaa/complaints/](http://www.hhs.gov/ocr/privacy/hipaa/complaints/).
- We will not retaliate against you for filing a complaint.

#### Our Uses and Disclosures

We typically use or share your information in the following ways:

**To provide our services to you**

We gather certain information from you through our platform, and then make that information available to you, or any third-party at your direction (i.e., you can download the information, or you could ask us to e-mail or send the information to your doctor, health plan, caregiver or otherwise).

**Run our organization**

We can use and share your information to approve our services to you.

### How else can we use or share your information?

We are allowed or required to share your information in other ways – usually in ways that contribute to the public good, such as public health and research. We have to meet many conditions in the law before we can share your information for these purposes.
For more information see: [http://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html](http://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html).

**Conduct research**

We can use or share your information for health or medical research.

**Comply with the law**

We will share information about you if state or federal laws require it, including with the Department of Health and Human Services if it wants to see that we are complying with federal privacy law, or with your health plan in connection with our contractual obligations for the general purpose of providing this service.

**Address law enforcement and other government requests**

We can use or share information about you:

- For law enforcement purposes or with a law enforcement official
- With health oversight agencies for activities authorized by law

**Respond to lawsuits and legal actions**

We can share health information about you in response to a court or administrative order, or in response to a subpoena.

### Our Responsibilities

- We are required by law to maintain the privacy and security of your protected health information.
- We will let you know promptly if a breach occurs that may have compromised the privacy or security of your information.
- We must follow the duties and privacy practices described in this notice and give you a copy of it.
- We will not use or share your information other than as described here unless you tell us we can in writing. If you tell us we can, you may change your mind at any time. Let us know in writing if you change your mind.
- We never market or sell personal information

### For more information

see:&nbsp;[http://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html](http://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html).

#### Changes to the Terms of this Notice

We can change the terms of this notice, and the changes will apply to all information we have about you. The new notice will be available upon request, in our office, and on our web site.

#### Additional Important Information:

- Effective Date of this Notice **:** January 1, 2020
- Privacy Contact: Tina Cavaliere [compliance@vitaldecisions.net](mailto:compliance@vitaldecisions.net), 800-256-1227`

const textES = `
### Como funciona

En solo unos minutos, nuestra experiencia guiada le ayudará a expresar lo que más le importa, identificar a su apoderado de atención médica y designar sus deseos de tratamiento médico en una crisis médica.

Al final de la encuesta, podrá revisar, descargar y compartir su directiva anticipada para ayudarle a guiar su atención médica y garantizar que la atención que desea se alinee con la atención que recibe.

### AVISO Y CONSENTIMIENTO DE HIPAA

Este aviso describe como se puede usar y divulgar la información médica que nos proporciona a través de nuestra plataforma, o que nos proporciona (es decir, por correo electrónico, a través de nuestro sitio web, a través de una llamada telefónica, etc.) y como puede ser utilizada y divulgada y como puede acceder a esta información. 
__Porfavor revísela detenidamente__.

#### Nuestros Usos y Divulgaciones

Podemos usar y compartir su información a medida que:

-  Se proporcionen nuestros servicios como se identifica en mylivingvoice.com y en relación con la plataforma descrita anteriormente
-  Opere nuestra organización
-  Se ayude con problemas de seguridad y salud pública
-  Se lleven a cabo investigaciones
-  Se cumpla con la ley
-  Se atienden las solicitudes de las fuerzas policiales y otras solicitudes gubernamentales
-  Se responde a demandas y acciones legales

#### Sus Derechos

__Cuando se trata de su información, tiene ciertos derechos.__ Esta sección explica sus derechos y algunas de nuestras responsabilidades para ayudarle.

__Obtener una copia electrónica o impresa de la información médica que nos proporcionó.__

-  Puede solicitar ver u obtener una copia electrónica o impresa de su información médica y otra información médica que tengamos sobre usted enviando una solicitud por escrito a:

    __Vital Decisions__  
    __379 Thornall St 3rd Floor__  
    __Edison, NJ 08837__  
    __ATTN: Chief Clinical Officer__  

    __O por fax confidencial seguro a:__  
    __866-449-8856__  
    __ATTN: Chief Clinical Officer__  

-  Le proporcionaremos una copia o un resumen de su información médica, generalmente dentro de los 30 días posteriores a su solicitud. Podemos cobrar una tarifa razonable basada en el costo.

__Nos solicite que corrijamos su historial médico__

- You can ask us to correct health information about you that you think is incorrect or incomplete. You must make the request to amend in writing with a reason. The request should be requested on Request for Amendment of Health Information form and submitted to:

    __Vital Decisions__  
    __379 Thornall St 3rd Floor__  
    __Edison, NJ 08837__  
    __ATTN: Chief Clinical Officer__

    __Or by secure confidential fax to:__  
    __866-449-8856__  
    __ATTN: Chief Clinical Officer__

-  Podemos decir &quot;no&quot; a su solicitud, pero le informaremos el motivo por escrito dentro de los 60 días.

**Solicitar comunicaciones confidenciales**

- Puede pedirnos que nos comuniquemos con usted de una manera específica (por ejemplo, por teléfono de su casa o de la oficina) o que le enviemos un correo a una dirección diferente.
- Diremos &quot;sis&quot; a todas las solicitudes razonables.
- Sin embargo, si se comunica con nosotros de una manera específica (es decir, por correo electrónico no seguro), asumiremos que da su consentimiento para que nos comuniquemos con usted, en respuesta a su comunicación, a través del mismo mecanismo/manera, a menos que nos lo indique explícitamente lo contrario.

**Solicitarles que limitemos lo que usamos o compartimos**

- Puede pedirnos que no usemos o compartamos cierta información médica para el pago o nuestras operaciones. No estamos obligados a aceptar su solicitud y podemos decir &quot;no&quot; si esto afectaría su atención.

**Obtener una lista de aquellos con quienes hemos compartido su información médica o de salud**

- Puede solicitar una lista (contabilidad) de las veces que hemos compartido su información médica o de salud durante los 6 años anteriores a la fecha de su solicitud, con quien la compartimos y porque.

**Obtener una copia de este aviso de privacidad**

- Puede solicitar una copia impresa de este aviso en cualquier momento, incluso si ha aceptado recibir el aviso de forma electrónica. Le proporcionaremos una copia en papel a la brevedad posible.

**Elegir a alguien para que actúe por usted**

- Si le ha otorgado a alguien un poder notarial médico o si alguien es su tutor legal, esa persona puede ejercer sus derechos y tomar decisiones sobre su información médica.
- Nos aseguraremos de que la persona tenga esta autoridad y pueda actuar en su nombre antes de tomar cualquier acción.

**Presentar una queja si cree que se violan sus derechos**

- Puede presentar una queja si cree que hemos violado sus derechos comunicándose con nosotros enviando un correo electrónico a: [memberservices@vitaldecisions.net](mailto:memberservices@vitaldecisions.net)
- Puede presentar una queja ante el U.S. Department of Health and Human Services Office for Civil Rights enviando una carta a 200 Independence Avenue, S.W., Washington, D.C. 20201, llamando al 1-877-696-6775, o visitando [http://www.hhs.gov/ocr/privacy/hipaa/complaints/](http://www.hhs.gov/ocr/privacy/hipaa/complaints/).
- No tomaremos represalias contra usted por presentar una queja.

#### Nuestros Usos y Divulgaciones

Normalmente usamos o compartimos su información de la siguiente manera:

**Para brindarle nuestros servicios**

Recopilamos cierta información suya a través de nuestra plataforma y luego la ponemos a su disposición, o a cualquier tercero que indique (es decir, puede descargar la información, o puede pedirnos que le enviemos un correo electrónico o la información a su médico, plan de salud, cuidador o de otra manera).

**Operar nuestra organización**

Podemos usar y compartir su información para aprobar nuestros servicios.

### ¿De qué otra manera podemos usar o compartir su información?

Estamos autorizados u obligados a compartir su información de otras formas – generalmente de formas que contribuyan al bien público, como la salud pública y la investigación. Tenemos que cumplir con muchas condiciones con la ley antes de que podamos compartir su información para estos fines. Para más información,
vea: [http://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html](http://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html).

**Llevar a cabo investigaciones**

Podemos usar o compartir su información para llevar a cabo investigaciones médicas o de salud.

**Cumplir con la ley**

Compartiremos información sobre usted si las leyes estatales o federales lo requieren, incluso con el Departamento de Salud y Servicios Humanos si desea ver que estamos cumpliendo con la ley federal de privacidad, o con su plan de salud en relación con nuestras obligaciones contractuales con el propósito general de brindar este servicio.

**Abordar las solicitudes de cumplimiento de la ley y otras solicitudes gubernamentales**

Podemos usar o compartir información sobre usted:

- Para propósitos de cumplimiento de la ley o con un funcionario de cumplimiento de la ley
- Con agencias de supervisión de la salud para actividades autorizadas por ley

**Responder a demandas y acciones legales**

Podemos compartir información médica sobre usted en respuesta a una orden judicial o administrativa, o en respuesta a una citación.

### Nuestras Responsabilidades

- Estamos obligados por ley a mantener la privacidad y seguridad de su información médica protegida.
- Le informaremos de inmediato si se produce un incumplimiento que pueda haber comprometido la privacidad o seguridad de su información.
- Debemos seguir los deberes y las prácticas de privacidad que se describen en este aviso y proporcionarle una copia.
- No usaremos ni compartiremos su información de otra manera que no sea como se describe aquí, a menos que usted nos diga que podemos hacerlo por escrito. Si nos dice que podemos, puede cambiar de opinión en cualquier momento. Háganos saber por escrito si cambia de opinión.
- Nunca comercializamos ni vendemos información personal.

### Para más información

vea:&nbsp;[http://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html](http://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html).

#### Cambios a los Términos de este Aviso

Podemos cambiar los términos de este aviso y los cambios aplicarán a toda la información que tenemos sobre usted. El nuevo aviso estará disponible a pedido, en nuestra oficina y en nuestro sitio web.

#### Información Adicional Importante:

- Fecha de Vigencia de este Aviso **:** January 1, 2020
- Contacto de Privacidad: Tina Cavaliere [compliance@vitaldecisions.net](mailto:compliance@vitaldecisions.net), 800-256-1227`

export const getHIPPAATextByLocale = (locale) => {
  switch (locale) {
    case LOCALES.en:
      return textEN
    case LOCALES.es:
      return textES
    default:
      return textEN
  }
}
