import React, { useState } from 'react'
import LayoutLanding from '../directive/blocks/landing/LayoutLanding'
import OrganizationBlock from '../directive/blocks/landing/OrganizationBlock'
import LandingHero from '../directive/blocks/landing/LandingHero'
import WhatIs from '../directive/blocks/landing/WhatIs'
import Time from '../directive/blocks/landing/Time'
import Why from '../directive/blocks/landing/Why'
import Reasons from '../directive/blocks/landing/Reasons'
import Share from '../directive/blocks/landing/Share'
import { LandingContext } from './LandingContext'
import { useQuery } from '@apollo/react-hooks'
import { getInnerPageRedirectUrl } from '../common/innerPageRedirect'
import { GET_ME } from '../common/queries'
import { SIGNIN_URL, DIRECTIVE_URL } from '../constants/UrlConstants'
import i18n from '../i18n'

const Landing = () => {
  const [loginLink, setLoginLink] = useState(SIGNIN_URL)

  useQuery(GET_ME, {
    onCompleted: (data) => {
      const redirectUrl = getInnerPageRedirectUrl(data.me)
      setLoginLink(redirectUrl)
      i18n.changeLanguage(data.me.profile.locale)
      localStorage.setItem('lang', data.me.profile.locale)
    },
  })

  return (
    <LandingContext.Provider value={{ startLink: DIRECTIVE_URL, loginLink }}>
      <LayoutLanding>
        <div className="landing">
          <OrganizationBlock />
          <LandingHero />
          <WhatIs />
          <Time />
          <Why />
          <Reasons />
          <Share />
        </div>
      </LayoutLanding>
    </LandingContext.Provider>
  )
}

export default Landing
