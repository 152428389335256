import { filter, compose, isEmpty } from 'ramda'
import { useTranslation } from 'react-i18next'
import { getDirectiveSteps } from '../TheDirective'
import { DIRECTIVE_URL } from '../../constants/UrlConstants'

export const useAnswers = ({ answers, proxies, profile, productType }) => {
  const { t } = useTranslation()
  const steps = getDirectiveSteps(productType, answers)(t)

  const summaryAnswers = steps.map(({ slug, isRequired, isAnswered, setInitialValues, summary, dashboard }) => ({
    url: `${DIRECTIVE_URL}/${slug}`,
    isRequired,
    isAnswered: isAnswered(answers, proxies, profile),
    answers: setInitialValues(answers, proxies, profile),
    summary,
    dashboard,
  }))

  const isDirectiveComplete = compose(
    isEmpty(),
    filter(({ isRequired, isAnswered }) => !isAnswered && isRequired),
  )(summaryAnswers)

  return { summaryAnswers, isDirectiveComplete }
}
