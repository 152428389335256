import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

import Layout from '../directive/blocks/Layout'
import Container from '../directive/blocks/Container'
import ForWhom from '../directive/blocks/ForWhom'
import PageTitle from '../directive/blocks/PageTitle'
import Tile from '../directive/blocks/Tile'
import DefinitionsList from '../directive/blocks/DefinitionsList'
import Button from '../directive/blocks/Button'
import PageSeparator from '../directive/blocks/PageSeparator'
import Columns from '../directive/blocks/Columns'
import ColumnTop from '../directive/blocks/ColumnTop'
import ColumnLeft from '../directive/blocks/ColumnLeft'
import { ReactComponent as ArrowBack } from '../assets/images/arrow-back.svg'
import { forAuth, UserContext } from '../hoc'
import { DELETE, POST, REQUESTS, redirectToLogin, clearAuthToken } from '../common/requests'
import { showError, showSuccess } from '../common/toastUtils'

const UserProfile = () => {
  const { t } = useTranslation()
  const { profile } = React.useContext(UserContext)
  const [isHandlingLogout, setIsHandlingLogout] = useState(false)
  const [isHandlingResetPassword, setIsHandlingResetPassword] = useState(false)

  const logout = async () => {
    setIsHandlingLogout(true)
    await DELETE(REQUESTS.LOGOUT)()
    clearAuthToken()
    redirectToLogin(false)
    setIsHandlingLogout(false)
  }

  const resetPassword = async () => {
    setIsHandlingResetPassword(true)
    const { message } = await POST(REQUESTS.RESET_PASSWORD, { user: { email: profile.email } })()
    setIsHandlingResetPassword(false)
    if (message === 'Internal Server Error') {
      showError(t('signIn.error'))
    } else {
      showSuccess(t('signIn.passwordResetSuccessMessage'))
    }
    setTimeout(redirectToLogin, 5500)
  }

  return (
    <Layout>
      <div className="page-profile">
        <Container>
          <ColumnTop>
            <ForWhom>
              <Link to="/dashboard">
                <ArrowBack className="icon -arrow-back" /> {t('profile.linkBack')}
              </Link>
            </ForWhom>
            <PageTitle params={{ text: t('profile.title') }} />
          </ColumnTop>
          <Columns>
            <ColumnLeft>
              <Tile title={t('profile.cardTitle')} white editLink="/profile/edit">
                <DefinitionsList
                  items={[
                    {
                      term: t('form.firstNameLabel'),
                      definition: profile.firstName,
                    },
                    {
                      term: t('form.lastNameLabel'),
                      definition: profile.lastName,
                    },
                    {
                      term: t('form.emailLabel'),
                      definition: profile.email,
                    },
                    {
                      term: t('form.stateLabel'),
                      definition: profile.residenceState || 'N/A',
                    },
                    {
                      term: t('form.dateOfBirthLabel'),
                      definition: profile.dateOfBirth ? format(parseISO(profile.dateOfBirth), 'MMMM d, yyyy') : '-',
                    },
                  ]}
                />
              </Tile>
              <Button
                className="-link -wide"
                disabled={isHandlingResetPassword}
                onClick={isHandlingResetPassword ? null : resetPassword}
              >
                {t('profile.resetPasswordButton')}
              </Button>
              <PageSeparator noMargin />
              <Button className="-link -wide -danger" disabled={isHandlingLogout} onClick={logout}>
                {t('profile.logOutButton')}
              </Button>
              <PageSeparator noMargin />
            </ColumnLeft>
          </Columns>
        </Container>
      </div>
    </Layout>
  )
}

export default forAuth(UserProfile)
