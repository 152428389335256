import React from 'react'
import { Form, Field } from 'react-final-form'

import FormFields from '../directive/blocks/FormFields'
import FormInput from '../directive/blocks/FormInput'
import { required, chain, strongPassword, equalPasswords } from '../inputs/validators'
import Layout from '../directive/blocks/Layout'
import Container from '../directive/blocks/Container'
import PageTitle from '../directive/blocks/PageTitle'
import Tile from '../directive/blocks/Tile'
import Columns from '../directive/blocks/Columns'
import ColumnTop from '../directive/blocks/ColumnTop'
import ColumnLeft from '../directive/blocks/ColumnLeft'
import ButtonGroup from '../directive/blocks/ButtonGroup'
import Button from '../directive/blocks/Button'
import { PUT, REQUESTS, redirectToLogin } from '../common/requests'
import { parseUrlParams } from '../common/URL'
import { useTranslation } from 'react-i18next'

const RecoveryForm = ({ onSubmit }) => {
  const { t } = useTranslation()

  return (
    <Form
      onSubmit={onSubmit}
      render={({ values, handleSubmit }) => (
        <form id="recoveryForm" onSubmit={handleSubmit}>
          <FormFields>
            <Field name="password" validate={chain(required, strongPassword)} type="password">
              {({ input, meta }) => (
                <FormInput label={t('form.newPasswordLabel')} placeholder="******" input={input} meta={meta} />
              )}
            </Field>
            <Field
              name="password_confirmation"
              validate={chain(required, strongPassword, equalPasswords(values.password))}
              type="password"
            >
              {({ input, meta }) => (
                <FormInput label={t('form.confirmNewPasswordLabel')} placeholder="******" input={input} meta={meta} />
              )}
            </Field>
            <ButtonGroup className="-survey-nav">
              <Button type="submit">{t('passwordRecovery.submitButton')}</Button>
            </ButtonGroup>
          </FormFields>
        </form>
      )}
    />
  )
}

const RecoveryPage = ({ location: { search } }) => {
  const { t } = useTranslation()

  const onSubmit = async ({ password, password_confirmation }) => {
    const { reset_password_token } = parseUrlParams(search)
    const { error } = await PUT(
      REQUESTS.CHANGE_PASSWORD,
      JSON.stringify({
        user: { password, password_confirmation, reset_password_token },
      }),
    )()
    if (!error) {
      redirectToLogin()
    }
  }

  return (
    <Layout>
      <div className="page-recovery">
        <Container>
          <ColumnTop>
            <PageTitle params={{ text: t('passwordRecovery.title') }} />
          </ColumnTop>
          <Columns>
            <ColumnLeft>
              <Tile white>
                <RecoveryForm onSubmit={onSubmit} />
              </Tile>
            </ColumnLeft>
          </Columns>
        </Container>
      </div>
    </Layout>
  )
}

export default RecoveryPage
