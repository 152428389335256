import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'
import { ApolloProvider } from '@apollo/react-hooks'
import ApolloClient from 'apollo-boost'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'

import './i18n'
import {
  getAuthToken,
  createRedirectUrl,
  isPublicPage,
} from './common/requests'
import { configureToasts, showError } from './common/toastUtils'
import './styles/scaffolding.scss'

import Landing from './landing/Landing'
import Directive from './directive/Directive'
import ExecuteDirective from './directive/ExecuteDirective'
import * as Routes from './constants/UrlConstants'
import NotFound from './CommonPages/Notfound'
import UserProfile from './profile/UserProfile'
import DirectiveSummary from './directive/DirectiveSummary'
import ShareDirective from './ShareDirective/ShareDirective'
import UserDashboard from './UserDashboard/UserDashboard'
import ScrollToTop from './inputs/ScrollToTop'
import SignIn from './signIn/SignIn'
import SignUp from './signUp/SignUp'
import UserProfileEdit from './profile/UserProfileEdit'
import PasswordRecover from './passwordRecover/PasswordRecover'
import PasswordReset from './passwordReset/PasswordReset'
import TermsOfService from './termsOfService/TermsOfService'
import PrivacyPolicy from './privacyPolicy/PrivacyPolicy'
import PasswordChange from './passwordChange/PasswordChange'
import { getPathPrefix } from './common/organizationTheme'
import Documents from './documents/documents'

smoothscroll.polyfill()
configureToasts()

const cache = new InMemoryCache({
  addTypename: true,
  dataIdFromObject: (obj) => defaultDataIdFromObject(obj),
})

const pathPrefix = getPathPrefix() ? `/${getPathPrefix()}`: ''
const client = new ApolloClient({
  cache,
  uri: `${pathPrefix}/graphql`,
  request: async (operation) => {
    const token = getAuthToken()
    const headers = token ? { authorization: `Bearer ${token}` } : {}

    operation.setContext({
      headers,
    })
  },

  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) => showError(error.message))

      console.log(graphQLErrors)
    }

    if (networkError) {
      console.log(networkError)

      switch (networkError.statusCode) {
        case 403:
        case 401: {
          if (!isPublicPage()) {
            window.location.assign(createRedirectUrl())
          }
          break
        }

        case 500:
        default:
          showError(networkError.toString())
      }
    }
  },
})


const App = () => {
  return (
    <Router basename={pathPrefix}>
      <ApolloProvider client={client}>
        <ScrollToTop />
        <Switch>
          <Route exact path={Routes.BASE_URL} component={Landing} />
          <Route exact path={Routes.DIRECTIVE_URL} component={Directive} />
          <Route path={Routes.DIRECTIVE_SUMMARY_URL} component={DirectiveSummary} />
          <Route exact path={Routes.EXECUTE_DIRECTIVE_URL} component={ExecuteDirective} />
          <Route path={Routes.DIRECTIVE_SLUG_URL} component={Directive} />
          <Route exact path={Routes.DASHBOARD_URL} component={UserDashboard} />
          <Route exact path={Routes.SHARE_URL} component={ShareDirective} />
          <Route exact path={Routes.PROFILE_URL} component={UserProfile} />
          <Route exact path={Routes.DOCUMENTS_URL} component={Documents} />
          <Route exact path={Routes.PROFILE_EDIT_URL} component={UserProfileEdit} />
          <Route exact path={Routes.SIGNIN_URL} component={SignIn} />
          <Route exact path={Routes.SIGNUP_URL} component={SignUp} />
          <Route exact path={Routes.PASSWORD_RESET_URL} component={PasswordReset} />
          <Route exact path={Routes.PASSWORD_RECOVERY_URL} component={PasswordRecover} />
          <Route exact path={Routes.PASSWORD_CHANGE_URL} component={PasswordChange} />
          <Route exact path={Routes.TERMS_OF_SERVICE_URL} component={TermsOfService} />
          <Route exact path={Routes.PRIVACY_POLICY_URL} component={PrivacyPolicy} />
          <Route component={NotFound} />
        </Switch>
      </ApolloProvider>
    </Router>
  )
}

export default App
