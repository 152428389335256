import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PageTitle from '../directive/blocks/PageTitle'
import PageText from '../directive/blocks/PageText'
import { parseUrlParams } from '../common/URL'
import ShareWithProxy from './blocks/ShareWithProxy'
import Layout from '../directive/blocks/Layout'
import Container from '../directive/blocks/Container'
import ButtonGroup from '../directive/blocks/ButtonGroup'
import Button from '../directive/blocks/Button'
import ForWhom from '../directive/blocks/ForWhom'
import { ReactComponent as ArrowBack } from '../assets/images/arrow-back.svg'
import ShareWithPhysician from './blocks/ShareWithPhysician'
import ShareWithHealthPlan from './blocks/ShareWithHealthPlan'
import ShareWithOthers from './blocks/ShareWithOthers'
import Columns from '../directive/blocks/Columns'
import ColumnTop from '../directive/blocks/ColumnTop'
import ColumnLeft from '../directive/blocks/ColumnLeft'
import { forAuth, UserContext } from '../hoc'
import { useTranslation } from 'react-i18next'
import ShareWithAdVault from './blocks/ShareWithAdVault'

const ShareDirective = ({ location: { search } }) => {
  const { t } = useTranslation()
  const { currentOrganization } = useContext(UserContext)

  const urlParams = parseUrlParams(search)

  const { state: { documentId, documentType, fileName } } = useLocation()

  const sharingComplete = () => {
    return false
  }

  return (
    <Layout>
      <Container>
        <ColumnTop>
          {urlParams.directive_success && <PageTitle params={{ text: t('shareDirective.success.title') }} />}
          {!urlParams.directive_success && (
            <ForWhom>
              <Link to="/documents">
                <ArrowBack className="icon -arrow-back" /> {t('shareDirective.linkBack')}
              </Link>
            </ForWhom>
          )}
        </ColumnTop>

        <Columns>
          <ColumnLeft>
            {urlParams.directive_success && (
              <PageText
                params={{
                  text: `${t('shareDirective.success.text.congratulations')}\n\n${t(
                    'shareDirective.success.text.saved',
                  )}`,
                }}
              />
            )}

            <ShareWithProxy documentInfo={{ documentId: documentId, fileName: fileName }} />
            <ShareWithPhysician documentInfo={{ documentId: documentId, fileName: fileName }} />
            <ShareWithOthers documentInfo={{ documentId: documentId, fileName: fileName }} />
            {currentOrganization.adVaultEnabled
              && documentType === 'AdvanceDirective'
              && <ShareWithAdVault />}

            <ButtonGroup className="-survey-nav">
              {sharingComplete() && (
                <Button type="button" to="/dashboard">
                  {t('shareDirective.success.goToLivingVoiceButton')}
                </Button>
              )}
              {!sharingComplete() && (
                <Button type="button" to="/dashboard" className="-link -wide">
                  {t('shareDirective.continueButton')}
                </Button>
              )}
            </ButtonGroup>
          </ColumnLeft>
        </Columns>
      </Container>
    </Layout>
  )
}

export default forAuth(ShareDirective)
