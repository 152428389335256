import React, { useState, useContext } from 'react'
import { Field, Form } from 'react-final-form'
import { find, propEq } from 'ramda'
import { useMutation } from '@apollo/react-hooks'
import { format, parseISO } from 'date-fns'

import Button from '../../directive/blocks/Button'
import FormFields from '../../directive/blocks/FormFields'
import FormInput from '../../directive/blocks/FormInput'
import Tile from '../../directive/blocks/Tile'
import FormSelect from '../../directive/blocks/FormSelect'
import TooltipList from '../../directive/blocks/TooltipList'
import DatePicker from '../../inputs/DatePicker'

import { chain, required, emailFormat, datePast, nameValidation } from '../../inputs/validators'
import { UserContext } from '../../hoc'
import { SHARE_WITH_DOCTOR } from '../../common/mutations'
import { updateCacheSharedWith } from '../../common/cacheUtils'
import FormError from '../../directive/blocks/FormError'
import { useAnswers } from '../../directive/hooks/useAnswers'
import SelectorCheckbox from '../../directive/blocks/SelectorCheckbox'

import MD from '../../directive/blocks/Markdown'
import Modal from '../../directive/blocks/Modal'

import { getHIPPAATextByLocale, HIPAANoticeTitle } from './HIPPAAText'
import { EmailConsentTitle } from './emailConsentText'
import { useTranslation } from 'react-i18next'
import { LOCALE_OPTIONS } from '../../constants/locales'

const ShareWithPhysician = (props) => {
  const { t } = useTranslation()

  const { sharedWithDoctors } = useContext(UserContext)
  const [shareWithDoctor] = useMutation(SHARE_WITH_DOCTOR)
  const [formExpanded, setFormExpanded] = useState(false)

  const { documentInfo: { documentId, fileName } } = props

  const onExpand = () => {
    setFormExpanded(!formExpanded)
  }

  const onSubmit = async ({ dateOfBirth, fullName, email, locale }) => {
    await shareWithDoctor({
      variables: {
        dateOfBirth: dateOfBirth,
        email: email,
        fullName: fullName,
        locale: locale.value,
        fileName: fileName,
        documentId: documentId,
      },
      update: updateCacheSharedWith('shareWithDoctor', 'sharedWithDoctors'),
    })
    setFormExpanded(false)
  }

  return (
    <div>
      {!!sharedWithDoctors.length && (
        <Tile title={t('shareWithDoctor.sentTo')} success onClick={onExpand}>
          {sharedWithDoctors.map(({ id, fullName, email, createdAt, fileName }) => (
            <p key={id}>
              {fullName}
              <br />
              {email}
              <br />
              {fileName}
              <br />
              {format(parseISO(createdAt), 'MM/dd/yyyy')}
            </p>
          ))}
        </Tile>
      )}
      <>
        <Tile title={t('shareWithDoctor.title')} expandable expanded={formExpanded} onTitleClick={onExpand}>
          <p>{t('shareWithDoctor.text')}</p>
          <TooltipList
            params={{
              tooltips: [
                {
                  title: t('shareWithDoctor.tooltips.0.title'),
                  reTitle: t('shareWithDoctor.tooltips.0.reTitle'),
                  text: t('shareWithDoctor.tooltips.0.text'),
                },
              ],
            }}
          />
          <ShareWithPhysicianForm onSubmit={onSubmit} />
        </Tile>
      </>
    </div>
  )
}

const ShareWithPhysicianForm = ({ onSubmit }) => {
  const { t, i18n } = useTranslation()

  const me = useContext(UserContext)
  const { isDirectiveComplete } = useAnswers(me)
  const { profile } = me

  const [HIPPAAIsOpen, setHIPPAAIsOpen] = useState(false)
  const [emailConsentIsOpen, setEmailConsentIsOpen] = useState(false)

  const initialDateOfBirthValue = profile.dateOfBirth && parseISO(profile.dateOfBirth)

  const currentLocaleOption = find(propEq('value', i18n.language))(LOCALE_OPTIONS)

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ locale: currentLocaleOption }}
      render={({ submitting, handleSubmit, values }) => (
        <form id="share-with-physician" onSubmit={handleSubmit}>
          <FormFields>
            <Field
              name="dateOfBirth"
              validate={chain(required, datePast)}
              initialValue={initialDateOfBirthValue}
              render={({ input, meta }) => (
                <DatePicker
                  label={t('form.yourDateOfBirthLabel')}
                  type="text"
                  input={input}
                  meta={meta}
                  showMonthDropdown
                  showYearDropdown
                  maxDate={new Date()}
                  yearDropdownItemNumber={120}
                  scrollableYearDropdown
                  white
                />
              )}
            />
            <Field
              name="fullName"
              validate={chain(required, nameValidation)}
              render={({ input, meta }) => (
                <FormInput
                  label={t('form.physicianNameLabel')}
                  placeholder={t('form.physicianNamePlaceholder')}
                  type="text"
                  input={input}
                  meta={meta}
                  white
                />
              )}
            />
            <Field
              name="email"
              validate={chain(required, emailFormat)}
              render={({ input, meta }) => (
                <FormInput
                  label={t('form.physicianEmailLabel')}
                  placeholder={t('form.physicianEmailPlaceholder')}
                  type="text"
                  input={input}
                  meta={meta}
                  white
                />
              )}
            />
            <Field name="locale" validate={required}>
              {({ input, meta }) => (
                <FormSelect
                  label={t('form.languageLabel')}
                  placeholder={t('form.languagePlaceholder')}
                  options={LOCALE_OPTIONS}
                  input={input}
                  meta={meta}
                  white
                />
              )}
            </Field>
            <Field type="checkbox" name={'hippa_checked'}>
              {({ input, meta }) => (
                <SelectorCheckbox
                  name={'hippa_checked'}
                  label={
                    <HIPAANoticeTitle
                      onClick={(event) => {
                        event.preventDefault()
                        setHIPPAAIsOpen(true)
                      }}
                    />
                  }
                  input={input}
                  meta={meta}
                />
              )}
            </Field>
            <Field type="checkbox" name={'email_checked'}>
              {({ input, meta }) => (
                <SelectorCheckbox
                  name={'email_checked'}
                  label={
                    <EmailConsentTitle
                      onClick={(event) => {
                        event.preventDefault()
                        setEmailConsentIsOpen(true)
                      }}
                    />
                  }
                  input={input}
                  meta={meta}
                />
              )}
            </Field>
            {!isDirectiveComplete && (
              <FormError className="-with-margin-top">{t('shareDirective.notCompleteError')}</FormError>
            )}
          </FormFields>
          <Modal
            isOpen={HIPPAAIsOpen}
            onRequestClose={() => setHIPPAAIsOpen(false)}
            title={t('shareWith.HIPAANotice.modalTitle')}
          >
            <div className="modal_text">
              <MD allowParagraphs>{getHIPPAATextByLocale(i18n.language)}</MD>
            </div>
          </Modal>

          <Modal
            isOpen={emailConsentIsOpen}
            onRequestClose={() => setEmailConsentIsOpen(false)}
            title={t('shareWith.emailConsent.modalTitle')}
          >
            <div className="modal_text">
              <MD allowParagraphs>{t('shareWith.emailConsent.modalText')}</MD>
            </div>
          </Modal>
          <Button
            disabled={submitting || !isDirectiveComplete || !values.hippa_checked || !values.email_checked}
            type="submit"
          >
            {t('shareDirective.sendButton')}
          </Button>
        </form>
      )}
    ></Form>
  )
}

export default ShareWithPhysician
