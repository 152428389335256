import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { startsWith } from 'ramda'
import { parse } from 'qs'
import { useLazyQuery } from '@apollo/react-hooks'

import { PASSWORD_RECOVERY_URL, PASSWORD_RESET_URL, SIGNUP_URL, PASSWORD_CHANGE_URL } from '../constants/UrlConstants'
import Layout from '../directive/blocks/Layout'
import Container from '../directive/blocks/Container'
import PageTitle from '../directive/blocks/PageTitle'
import Button from '../directive/blocks/Button'
import Columns from '../directive/blocks/Columns'
import ColumnTop from '../directive/blocks/ColumnTop'
import Modal from '../directive/blocks/Modal'

import FormInput from '../directive/blocks/FormInput'
import FormFields from '../directive/blocks/FormFields'
import { chain, emailFormat, required, strongPassword } from '../inputs/validators'
import { POST, REQUESTS, setAuthToken, clearStorage } from '../common/requests'
import { showSuccess } from '../common/toastUtils'
import { getInnerPageRedirectUrl } from '../common/innerPageRedirect'
import { GET_ME } from '../common/queries'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { siteHasLoginHelp } from '../directive/hooks/siteHasLoginHelp'
import LoginHelpImage from '../assets/images/login-help.png'

const SignIn = ({ history }) => {
  const { t } = useTranslation()
  const includeLoginHelp = siteHasLoginHelp()
  const [loginHelpModalOpen, setLoginHelpModalOpen] = useState(false)

  let signInErrorMessage = ''
  const setErrorMessage = (message) =>{
    if(message === 'Invalid Email or password.'){
      return signInErrorMessage = 'signIn.form.submitError'
    } else if (message === 'You have one more attempt before your account is locked.'){
      return signInErrorMessage = 'signIn.form.lastAttempt'
    }else if(message === 'Your account is locked.'){
      return signInErrorMessage = 'signIn.form.accountLocked'
    }
  }

  const [fetchProfile, { data: { me = null } = {}, loading: loadingProfile }] = useLazyQuery(GET_ME, {
    onCompleted: () => {
      if (me.requiresPasswordChange) {
        history.replace(PASSWORD_CHANGE_URL)
      } else {
        history.replace(getReplaceUrl())
      }
    },
  })

  const handleLoginHelpButton = e => {
    e.preventDefault()
    setLoginHelpModalOpen(true)
  }

  useEffect(() => {
    const { from } = parse(window.location.search, { ignoreQueryPrefix: true })

    if (from && startsWith(PASSWORD_RECOVERY_URL, from)) {
      showSuccess(t('signIn.passwordRecoverySuccessMessage'))
    } else if (from && startsWith(PASSWORD_RESET_URL, from)) {
      showSuccess(t('signIn.passwordResetSuccessMessage'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getReplaceUrl = () => {
    const { from } = parse(window.location.search, { ignoreQueryPrefix: true })
    if (!from || startsWith(PASSWORD_RECOVERY_URL, from) || startsWith(PASSWORD_RESET_URL, from)) {
      return getInnerPageRedirectUrl(me)
    }
    return from
  }

  const loginUser = async ({ email, password }) => {
    const data = await POST(REQUESTS.LOGIN, {
      user: { email, password },
    })()

    clearStorage()

    if (data.status !== 401) {
      const { access_token: accessToken } = data
      setAuthToken(accessToken)
      fetchProfile()
    } else {
      return { [FORM_ERROR]: setErrorMessage(data.message) }
    }
  }

  return (
    <Layout>
      <Container>
        <Columns>
          <ColumnTop>
            <PageTitle params={{ text: t('signIn.title') }} />
            <Form
              onSubmit={loginUser}
              render={({ handleSubmit, submitting, pristine, submitError }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <FormFields>
                      {submitError && <div className="form-error">{t(signInErrorMessage)}</div>}
                      <Field name="email" validate={chain(required, emailFormat)}>
                        {({ input, meta }) => (
                          <FormInput
                            label={t('form.emailLabel')}
                            placeholder={t('form.emailPlaceholder')}
                            type="text"
                            input={input}
                            meta={meta}
                          />
                        )}
                      </Field>
                      <Field name="password" validate={chain(required, strongPassword)} type="password">
                        {({ input, meta }) => (
                          <FormInput label={t('form.passwordLabel')} placeholder="******" input={input} meta={meta} />
                        )}
                      </Field>
                    </FormFields>
                    <FormFields>
                      <div className="page-text">
                        {includeLoginHelp &&
                          <Button
                            onClick={(e) => handleLoginHelpButton(e)}
                            className="-link-flex"
                          >
                            <span>{t('signIn.forgotPassword.helpModal')} </span>
                          </Button>
                        }
                        <p>
                          <span>{t('signIn.forgotPassword.question')} </span>
                          <Link to={PASSWORD_RESET_URL}>{t('signIn.forgotPassword.linkText')}</Link>
                        </p>
                        <p>
                          <span>{t('signIn.signUp.question')} </span>
                          <Link to={SIGNUP_URL}>{t('signIn.signUp.linkText')}</Link>
                        </p>
                      </div>
                    </FormFields>
                    <Button disabled={submitting || pristine || loadingProfile} type="submit">
                      {t('signIn.submitButton')}
                    </Button>
                  </form>
                )
              }}
            />
          </ColumnTop>
        </Columns>
      </Container>
      {loginHelpModalOpen &&
        <Modal
          onRequestClose={() => setLoginHelpModalOpen(false)}
          isOpen={true}
          id='modal-image'
        >
          <img
            src={LoginHelpImage}
            alt="Instructions for resetting password"
            className='instruction-image'
          />

        </Modal>
      }
    </Layout>
  )
}

export default SignIn
