import React from 'react'
import cn from 'classnames'
import MD from './Markdown'

import '../../styles/blocks/form-error.scss'

const FormError = ({ className, children }) => (
  <div className={cn('form-error', className)}>
    <MD>{children}</MD>
  </div>
)

export default FormError
