const monthsData = [
  { value: 1, label: 'January', days: 31 },
  { value: 2, label: 'February', days: 28 },
  { value: 3, label: 'March', days: 31 },
  { value: 4, label: 'April', days: 30 },
  { value: 5, label: 'May', days: 31 },
  { value: 6, label: 'June', days: 30 },
  { value: 7, label: 'July', days: 31 },
  { value: 8, label: 'August', days: 31 },
  { value: 9, label: 'September', days: 30 },
  { value: 10, label: 'October', days: 31 },
  { value: 11, label: 'November', days: 30 },
  { value: 12, label: 'December', days: 31 },
]

export const monthsSelectOptions = [{ value: null, label: 'Select an option', days: 0 }, ...monthsData]

export const getDaysSelectOptions = month =>
  month > 0
    ? Array.from(Array(monthsSelectOptions[month].days + 1), (_, i) => ({
        value: i || null,
        label: i || 'Select an option',
      }))
    : []
