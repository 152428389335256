const url = window.location.href
const organization = url.replace(/^https?:\/\//, '').split('.')[0]

const sitesWithLoginHelp = [
  'centene',
  'bcbsm',
  'fallonhealth',
  'bsc',
  'fb',
  'fbmedicare',
  'bcbsmm',
  'aetnaflsnp',
  'cnchealthplan',
  'uawtrust',
  'molinafl',
  'healthnewengland',
  'aetnafl',
  'aetnabhva',
  'countycare',
  'oakstreethealth',
]

export const siteHasLoginHelp = () => {
  if (sitesWithLoginHelp.includes(organization)) return true
  else return false
}
