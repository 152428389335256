import React, { useContext, useMemo } from 'react'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import createDecorator from 'final-form-focus'
import cn from 'classnames'
import { findIndex, propEq } from 'ramda'

import MD from './blocks/Markdown'
import { getCurrentDirective, getDirectiveSteps } from './TheDirective'
import ProgressBar from './blocks/ProgressBar'
import Layout from './blocks/Layout'
import Container from './blocks/Container'
import ButtonGroup from './blocks/ButtonGroup'
import Button from './blocks/Button'
import NextBtnPlaceholder from './blocks/NextBtnPlaceholder'
import arrayMutators from 'final-form-arrays'
import Columns from './blocks/Columns'
import ColumnTop from './blocks/ColumnTop'
import ColumnLeft from './blocks/ColumnLeft'
import ColumnRight from './blocks/ColumnRight'
import SurveyNav from './blocks/SurveyNav'
import FormError from './blocks/FormError'
import Block from './blocks/Block'
import { forAuth, UserContext } from '../hoc'
import { useDirective } from './hooks/useDirective'
import '../styles/blocks/directive-form.scss'

import { ReactComponent as ArrowBack } from '../assets/images/arrow-back.svg'
import { DASHBOARD_URL } from '../constants/UrlConstants'

const focusOnErrors = createDecorator()

const MainContent = ({ blocks, formRenderProps: { handleSubmit, submitting, values, form } }) => (
  <>
    {blocks.map((block, index) => (
      <div key={index}>
        <Block
          className={cn({ '-desktop-hide': block.columnRight })}
          block={block}
          handleSubmit={handleSubmit}
          submitting={submitting}
          values={values}
          form={form}
        />
        {block.params && block.params.id && !block.hideBlockError && (
          <Field name={`${block.params.id}`}>
            {({ meta: { invalid, error, submitError } }) => (
              <>{invalid && (error || submitError) && <FormError>{error || submitError}</FormError>}</>
            )}
          </Field>
        )}
      </div>
    ))}
    <Field name="formError">
      {({ meta: { invalid, error, submitError } }) => (
        <>{invalid && (error || submitError) && <FormError>{error || submitError}</FormError>}</>
      )}
    </Field>
  </>
)

const Directive = ({ history, match: { params } }) => {
  const { t } = useTranslation()
  const { answers, proxies, profile, productType } = useContext(UserContext)
  const { onSubmit } = useDirective({ answers, proxies, profile, history })


  const currentDirective = getCurrentDirective(productType)(t)

  const slug = params.slug || currentDirective[0].slug
  let pageIndex = findIndex(propEq('slug', slug))(currentDirective)
  pageIndex = pageIndex < 0 ? 0 : pageIndex

  const page = currentDirective[pageIndex]
  const prevPage = page.getPrevStep(answers).url

  const steps = getDirectiveSteps(productType, answers)(t)
  const stepIndex = findIndex(propEq('slug', slug))(steps)
  const progress = (stepIndex * 100) / steps.length

  const [firstBlock, ...restBlocks] = page.blocks


  const handleSave = (values, form) => {
  const validationState = form.getState()
    if (validationState.valid !== true) {
      form.submit()
    } else {
      onSubmit(page)(values, form, () => history.push(DASHBOARD_URL))
    }
  }



  const initialValues = useMemo(() => {
    return page.setInitialValues(answers, proxies, profile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers, proxies, profile, pageIndex])

  const onLwsWelcomePage = productType === 'lws' && slug === 'welcome'
  const lwsWelcomeAnswer = { welcome: '1' }

  return (
    <Layout showProgress={true} progress={progress}>
      <Form
        key={slug}
        onSubmit={(values, form) => onSubmit(page)(values, form)}
        decorators={[focusOnErrors]}
        initialValues={onLwsWelcomePage ? lwsWelcomeAnswer : initialValues}
        mutators={{
          ...arrayMutators,
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
        }}
        render={({ handleSubmit, submitting, values, form }) => {
          return (
            <form id={slug} className="directive-form" onSubmit={handleSubmit}>
              <Container>
                <ProgressBar className="-in-content" progress={progress} />
                <ColumnTop>
                  <Block block={firstBlock} handleSubmit={handleSubmit} values={values} form={form} />
                  {page.fullWidthColumn && (
                    <MainContent blocks={restBlocks} formRenderProps={{ handleSubmit, submitting, values, form }} />
                  )}
                </ColumnTop>
                {!page.fullWidthColumn && (
                  <Columns>
                    <ColumnLeft>
                      <MainContent blocks={restBlocks} formRenderProps={{ handleSubmit, submitting, values, form }} />
                    </ColumnLeft>
                    <ColumnRight>
                      {restBlocks.map((block, index) => (
                        <div key={index}>
                          {block.columnRight && (
                            <Block key={index} block={block} handleSubmit={handleSubmit} values={values} form={form} />
                          )}
                        </div>
                      ))}
                    </ColumnRight>
                  </Columns>
                )}
              </Container>
              <SurveyNav>
                <Container className="survey-nav_container">
                  {page.nextBtnPlaceholder && (
                    <NextBtnPlaceholder>
                      <MD>{page.nextBtnPlaceholder.text}</MD>
                    </NextBtnPlaceholder>
                  )}
                  <ButtonGroup className={cn('-survey-nav', { '-no-prev': page.hidePrevBtn || !prevPage })}>
                    {!page.hideNextBtn && (
                      <ButtonGroup className="-survey-next-btns">
                        {!page.hideSaveButton && (
                          <Button
                            type="submit"
                            className="-secondary"
                            disabled={submitting}
                            isLoading={submitting}
                            onClick={() => handleSave(values, form)}
                          >
                            {t('common.directive.navigation.saveAndGoToDashboard')}
                          </Button>
                        )}
                        <Button type="submit" disabled={submitting} isLoading={submitting}>
                          {t('common.directive.navigation.continue')}
                        </Button>
                      </ButtonGroup>
                    )}
                    {!page.hidePrevBtn && prevPage && (
                      <Button to={prevPage} className="-link">
                        <span className="button_icon-wrap -arrow-back">
                          <ArrowBack className="button_icon icon -arrow-back" />
                        </span>
                        <span className="button_text">{t('common.directive.navigation.previousStep')}</span>
                      </Button>
                    )}
                  </ButtonGroup>
                </Container>
              </SurveyNav>
            </form>
          )
        }}
      />
    </Layout>
  )
}

export default forAuth(Directive)
