// Organizations that want a paragraph on the landing page
// Return the appropriate JSON from translations.json
// Org is pulled from URL
const organizationBlurb = {
  'noBlurb': '',
  'globalhealth': 'landing.organizationBlurb.GlobalHealth',
}

export const getOrganizationBlurb = org => {
  if (org in organizationBlurb) return organizationBlurb[org]
  else return organizationBlurb['noBlurb']
}
