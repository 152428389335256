import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../Container'
import { getOrganizationBlurb } from '../../hooks/getOrgBlurb'

import '../../../styles/blocks/landing/landing-hero.scss'

const OrganizationBlock = () => {
  const { t } = useTranslation()
  const url = window.location.href
  const organization = url.replace(/^https?:\/\//, '').split('.')[0]
  const blurb = getOrganizationBlurb(organization)


  return (
    <div className="landing-organization">
      <Container className="landing-container">
        <div className="landing-organization-block">{t(blurb)}</div>
      </Container>
    </div>
  )
}

export default OrganizationBlock
