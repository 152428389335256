import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function NotFound() {
  const { t } = useTranslation()

  return (
    <div>
      <h1>{t('notFound.title')}</h1>
      <ul>
        <li>
          <Link to="/">{t('notFound.homeLink')} </Link>
        </li>
      </ul>
    </div>
  )
}

export default NotFound
