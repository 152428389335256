import React, { useState, useContext } from 'react'
import { Field, Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { useMutation } from '@apollo/react-hooks'
import { format, parseISO } from 'date-fns'
import { find, propEq } from 'ramda'

import Button from '../../directive/blocks/Button'
import FormFields from '../../directive/blocks/FormFields'
import FormInput from '../../directive/blocks/FormInput'
import Tile from '../../directive/blocks/Tile'
import FormSelect from '../../directive/blocks/FormSelect'
import { chain, required, emailFormat } from '../../inputs/validators'
import { UserContext } from '../../hoc'
import { SHARE_WITH_PROXY } from '../../common/mutations'
import { updateCacheSharedWith } from '../../common/cacheUtils'
import SelectorCheckbox from '../../directive/blocks/SelectorCheckbox'
import FormError from '../../directive/blocks/FormError'
import { useAnswers } from '../../directive/hooks/useAnswers'

import MD from '../../directive/blocks/Markdown'
import Modal from '../../directive/blocks/Modal'

import { getHIPPAATextByLocale, HIPAANoticeTitle } from './HIPPAAText'
import { EmailConsentTitle } from './emailConsentText'
import { useTranslation } from 'react-i18next'
import { LOCALE_OPTIONS } from '../../constants/locales'

const ShareWithProxy = (props) => {
  const { t } = useTranslation()

  const { sharedWithProxies } = useContext(UserContext)

  const [shareWithProxy] = useMutation(SHARE_WITH_PROXY)

  const { documentInfo: { documentId, fileName } } = props

  const [formExpanded, setFormExpanded] = useState(false)

  const onExpand = () => {
    setFormExpanded(!formExpanded)
  }

  const onSubmit = async ({ proxy, email, locale }) => {
    await shareWithProxy({
      variables: {
        email: email,
        proxyId: proxy.value,
        locale: locale.value,
        fileName: fileName,
        documentId: documentId,
      },
      update: updateCacheSharedWith('shareWithProxy', 'sharedWithProxies'),
    })
    setFormExpanded(false)
  }

  return (
    <div>
      {!!sharedWithProxies.length && (
        <Tile title={t('shareWithProxy.sentTo')} success onClick={onExpand}>
          {sharedWithProxies.map((share) => (
            <p key={share.id}>
              {`${share.fullName}, ${t('shareWithProxy.healthcareProxy')}`}
              <br />
              {share.email}
              <br />
              {share.fileName}
              <br />
              {format(parseISO(share.createdAt), 'MM/dd/yyyy')}
            </p>
          ))}
        </Tile>
      )}
      <>
        <Tile title={t('shareWithProxy.title')} expandable expanded={formExpanded} onTitleClick={onExpand}>
          <p>{t('shareWithProxy.text')}</p>
          <ShareProxyForm onSubmit={onSubmit} />
        </Tile>
      </>
    </div>
  )
}

const ShareProxyForm = ({ onSubmit }) => {
  const { t, i18n } = useTranslation()

  const me = useContext(UserContext)
  const { isDirectiveComplete } = useAnswers(me)
  const [HIPPAAIsOpen, setHIPPAAIsOpen] = useState(false)
  const [emailConsentIsOpen, setEmailConsentIsOpen] = useState(false)
  const { proxies = [] } = me

  const currentLocaleOption = find(propEq('value', i18n.language))(LOCALE_OPTIONS)

  const proxiesOptions = proxies.map(({ id, firstName, lastName }) => ({
    value: id,
    label: `${firstName} ${lastName}`,
  }))

  const onProxyChange = ({ label, value }, form) => {
    form.mutators.setValue('proxy', { label, value })
    const proxy = find(propEq('id', value), proxies) || {}
    if (proxy.email) {
      form.mutators.setValue('email', proxy.email)
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ locale: currentLocaleOption }}
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value)
        },
      }}
      render={({ handleSubmit, form, values, submitting }) => {
        return (
          <form id="share-with-proxy" onSubmit={handleSubmit}>
            <FormFields>
              <Field name="proxy" validate={required}>
                {({ input, meta }) => (
                  <FormSelect
                    label={t('form.yourProxyLabel')}
                    placeholder={t('form.yourProxyPlaceholder')}
                    options={proxiesOptions}
                    input={{
                      ...input,
                      onChange: (value) => onProxyChange(value, form),
                    }}
                    meta={meta}
                    white
                  />
                )}
              </Field>
              <Field
                name="email"
                validate={chain(required, emailFormat)}
                render={({ input, meta }) => (
                  <FormInput
                    label={t('form.emailLabel')}
                    placeholder={t('form.emailPlaceholder')}
                    type="text"
                    input={input}
                    meta={meta}
                    white
                  />
                )}
              />
              <Field name="locale" validate={required}>
                {({ input, meta }) => (
                  <FormSelect
                    label={t('form.languageLabel')}
                    placeholder={t('form.languagePlaceholder')}
                    options={LOCALE_OPTIONS}
                    input={input}
                    meta={meta}
                    white
                  />
                )}
              </Field>
              <Field type="checkbox" name={'hippa_checked'}>
                {({ input, meta }) => (
                  <SelectorCheckbox
                    name={'hippa_checked'}
                    label={
                      <HIPAANoticeTitle
                        onClick={(event) => {
                          event.preventDefault()
                          setHIPPAAIsOpen(true)
                        }}
                      />
                    }
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <Field type="checkbox" name={'email_checked'}>
                {({ input, meta }) => (
                  <SelectorCheckbox
                    name={'email_checked'}
                    label={
                      <EmailConsentTitle
                        onClick={(event) => {
                          event.preventDefault()
                          setEmailConsentIsOpen(true)
                        }}
                      />
                    }
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              {!isDirectiveComplete && (
                <FormError className="-with-margin-top">{t('shareDirective.notCompleteError')}</FormError>
              )}
            </FormFields>
            <Modal
              isOpen={HIPPAAIsOpen}
              onRequestClose={() => setHIPPAAIsOpen(false)}
              title={t('shareWith.HIPAANotice.modalTitle')}
            >
              <div className="modal_text">
                <MD allowParagraphs>{getHIPPAATextByLocale(i18n.language)}</MD>
              </div>
            </Modal>

            <Modal
              isOpen={emailConsentIsOpen}
              onRequestClose={() => setEmailConsentIsOpen(false)}
              title={t('shareWith.emailConsent.modalTitle')}
            >
              <div className="modal_text">
                <MD allowParagraphs>{t('shareWith.emailConsent.modalText')}</MD>
              </div>
            </Modal>
            <Button
              disabled={submitting || !isDirectiveComplete || !values.hippa_checked || !values.email_checked}
              type="submit"
            >
              {t('shareDirective.sendButton')}
            </Button>
          </form>
        )
      }}
    ></Form>
  )
}

export default ShareWithProxy
